import { RedactionMode } from 'player/models/redaction-modes';
import { applySnapshot, ModelTreeNode } from 'ts-state-tree/tst-core';
import { createLogger } from 'app/logger';
import { Root } from '../root';
import { getBaseRoot } from '../app-root';
import { bugsnagNotify } from '@app/notification-service';

const log = createLogger('um:player-settings');

export type PlayerSettingsData = {
  playbackRate: number;
  redactionMode: RedactionMode;
  smartPauseEnabled: boolean;
  introShown?: boolean; // obsolete
};

/**
 * PlayerSettings
 *
 * user settings shared between web study player and soundbite player
 */

const DEFAULT_PLAYBACK_RATE = 0.8;
const DEFAULT_REDACTION_MODE = RedactionMode.SHOW_ALL;

export class PlayerSettings extends ModelTreeNode {
  static CLASS_NAME = 'PlayerSettings' as const;

  playbackRate: number = DEFAULT_PLAYBACK_RATE;
  redactionMode: RedactionMode = DEFAULT_REDACTION_MODE;
  smartPauseEnabled: boolean = false;
  introShown: boolean = false; // for now only used by the soundbite player

  static create(snapshot: any) {
    return super.create(PlayerSettings, snapshot) as PlayerSettings;
  }

  get root(): Root {
    return getBaseRoot(this);
  }

  isDifferent(settings: PlayerSettingsData): boolean {
    return (
      this.playbackRate !== settings.playbackRate ||
      this.redactionMode !== settings.redactionMode ||
      !!this.introShown !== !!settings.introShown
    );
  }

  setAll(settings: PlayerSettingsData) {
    log.info('Syncing player-settings into user data');
    applySnapshot(this, settings);
  }

  setPlaybackRate(value: number) {
    this.playbackRate = value;
  }

  setRedactionMode(value: RedactionMode) {
    this.redactionMode = value;
  }

  toggleSmartPause() {
    this.smartPauseEnabled = !this.smartPauseEnabled;
    log.debug('toggleSmartPause', this.smartPauseEnabled);
  }

  enableSmartPause() {
    this.smartPauseEnabled = true;
    this.root.userManager.persistUserData().catch(bugsnagNotify);
    log.debug('enableSmartPause', this.smartPauseEnabled);
  }

  disableSmartPause() {
    this.smartPauseEnabled = false;
    this.root.userManager.persistUserData().catch(bugsnagNotify);
    log.debug('disableSmartPause', this.smartPauseEnabled);
  }

  resetToDefault() {
    this.playbackRate = DEFAULT_PLAYBACK_RATE;
    this.redactionMode = DEFAULT_REDACTION_MODE;
  }

  // setIntroShown(value: boolean) {
  //   this.introShown = value;
  // }
}

import * as React from 'react';

import { styled } from '@naan/stitches.config';
import {
  PlayerViewController,
  PlayerViewControllerFactory,
} from 'components/ui/video-player/player-view-controller';
import { observer } from 'mobx-react';
import { ProgressBar } from 'components/ui/video-player/video-player2/progress-bar';
import { PausedOverlay } from './paused-overlay';
import { NotStartedOverlay } from './not-started-overlay';
import { EndOverlay } from './end-overlay';
import { SpinnerIcon } from 'components/ui/icons/spinner-icon';

import { createLogger } from '@common/log';
const log = createLogger('video-player');

const Backdrop = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
  background:
    'radial-gradient(66.5% 66.5% at 50% 50%, #2D597E 0%, #203D59 100%)',
});

const VideoWrapper = styled('div', {
  overflow: 'hidden',
  display: 'flex',
  placeItems: 'center',
  position: 'relative',
  width: '100%',
  height: '100%',

  // background gradient for controls
  '&:before': {
    content: '',
    display: 'block',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 160,
    background:
      'linear-gradient(180deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.00) 100%)',
    zIndex: 2,
  },

  '& > video': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    padding: 0,
    zIndex: 1,
  },

  '& > .loading': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },

  '& > .progress-bar': {
    position: 'absolute',
    right: 16,
    top: 16,
    zIndex: 4,
  },

  '& > .pause-button': {
    position: 'absolute',
    left: 16,
    top: 16,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& > button': {
      all: 'unset',
    },
  },

  // '& > .subtitles': {
  //   fontFamily: 'larsseit-bold',
  //   fontWeight: '400',
  //   fontSize: '22px',
  //   lineHeight: '28px',
  //   webkitFontSmoothing: 'antialiased',
  //   zIndex: 3,

  //   display: 'none',
  //   position: 'absolute',
  //   maxWidth: 'calc(100% - 60px)',
  //   paddingBlock: 6,
  //   paddingInline: 12,
  //   left: '50%',
  //   transform: 'translateX(-50%)',
  //   bottom: 'calc(22px + var(--sab))',
  //   borderRadius: 16,
  //   color: 'white',
  //   backgroundColor: 'rgba(50,50,50,.75)',
  //   textAlign: 'center',
  //   '&.show': {
  //     display: 'block',
  //   },
  // },

  '& video::cue': {
    fontFamily: 'larsseit-bold',
    fontWeight: '400',
    fontSize: '22px',
    lineHeight: '28px',
    webkitFontSmoothing: 'antialiased',
    color: 'white',
    backgroundColor: 'rgba(50,50,50,.75)',
  },

  '@media (min-aspect-ratio:1), (min-width: 1000px)': {
    aspectRatio: '2 / 3',
    width: 'auto',
    boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.20)',
    background: 'transparent',
    maxHeight: 860,
  },
  '@media (min-aspect-ratio:1) and (min-height: 400px)': {
    height: 'calc(100vh - 32px)',
    borderRadius: 16,
  },
});

const playerController = PlayerViewControllerFactory();

const PauseIcon = () => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx={20} cy={20} r="18.5" stroke="white" strokeWidth={3} />
    <path
      d="M17.6924 12.3077C18.1172 12.3077 18.4616 12.6521 18.4616 13.077V26.9231C18.4616 27.348 18.1172 27.6924 17.6924 27.6924H15.3847C14.9599 27.6924 14.6155 27.348 14.6155 26.9231V13.077C14.6155 12.6521 14.9599 12.3077 15.3847 12.3077H17.6924ZM24.6155 12.3077C25.0403 12.3077 25.3847 12.6521 25.3847 13.077V26.9231C25.3847 27.348 25.0403 27.6924 24.6155 27.6924H22.3078C21.883 27.6924 21.5386 27.348 21.5386 26.9231V13.077C21.5386 12.6521 21.883 12.3077 22.3078 12.3077H24.6155Z"
      fill="white"
    />
  </svg>
);

type OverlayCardType =
  | 'not-started'
  | 'playing-overlay'
  | 'end-overlay'
  | 'paused-overlay'
  | 'loading-overlay';

function getCard(
  playerController: PlayerViewController,
  videoWatched: boolean
): OverlayCardType {
  if (playerController.isNotStarted && !playerController.willAutoPlay) {
    return 'not-started';
  }

  if (playerController.isPlaying) {
    return 'playing-overlay';
  }

  // @armando, can't we assume the video is watched if we're in the ended state?
  // right now 'videoWatched' is undesireably false because this gets evaluated before the achievement state is updated
  if (playerController.isEnded /*&& videoWatched */) {
    return 'end-overlay';
  }

  if (playerController.canPlay) {
    return 'paused-overlay';
  }

  return 'loading-overlay';
}

export const PlayerOverlayCard = observer(
  ({
    title,
    poster,
    playerController,
    videoWatched,
    onComplete,
    onExit,
    onSkip,
  }: {
    title: string;
    poster: string;
    playerController: PlayerViewController;
    videoWatched: boolean;
    onComplete: () => void;
    onExit: () => void;
    onSkip?: () => void;
  }) => {
    const card = getCard(playerController, videoWatched);

    log.info('Rendering card', card);
    // debugger;

    switch (card) {
      case 'not-started':
        return (
          <NotStartedOverlay
            title={title}
            poster={poster}
            player={playerController}
            onExit={onExit}
            onSkip={onSkip}
          />
        );
      case 'playing-overlay':
        return (
          <div className="pause-button">
            <button onClick={() => playerController.videoElement.pause()}>
              <PauseIcon />
            </button>
          </div>
        );
      case 'end-overlay':
        return (
          <EndOverlay
            player={playerController}
            onComplete={onComplete}
            onExit={onExit}
          />
        );
      case 'paused-overlay':
        return (
          <PausedOverlay
            videoWatched={videoWatched}
            player={playerController}
            onExit={onExit}
            onSkip={onSkip}
            onComplete={onComplete}
          />
        );
      case 'loading-overlay':
        return (
          <div className="loading">
            <SpinnerIcon />
          </div>
        );
    }
  }
);

export const VideoPlayer2 = ({
  videoSrc,
  onComplete,
  onEnded,
  onExit,
  // onSkip,
  videoWatchedFn,
  title,
  poster,
}: {
  title: string;
  poster: string;
  videoSrc: string;
  onEnded: () => void;
  onComplete: () => void;
  onExit: () => void;
  // onSkip?: () => void;
  videoWatchedFn?: () => boolean;
}) => {
  const videoWatched = videoWatchedFn ? videoWatchedFn() : false;
  const videoElementRef = React.useRef<HTMLVideoElement>(null);

  const onSkip = React.useCallback(() => {
    playerController.goToEnd();
  }, []);

  React.useEffect(() => {
    if (videoElementRef.current) {
      playerController.initialize({
        src: videoSrc,
        videoElement: videoElementRef.current,
        onEnded: () => {
          // eslint-disable-next-line no-console
          // console.log('On Ended');
          onEnded();
        },
        onPlay: () => {
          // eslint-disable-next-line no-console
          // console.log('On Play');
        },
        onPause: () => {
          // eslint-disable-next-line no-console
          // console.log('On Pause');
        },
      });

      playerController.videoElement.addEventListener(
        'loadeddata',
        (_event: Event) => {
          ///under certain conditions we want the video to be in the last frame
          if (videoWatchedFn()) {
            playerController.goToEnd();
          }
        }
      );
    }

    return () => {
      if (playerController) {
        // we most likely will want to reset the player state, too
        playerController.reset();
      }
    };
  }, [videoSrc, videoElementRef, videoWatchedFn, onEnded]);

  return (
    <Backdrop>
      <VideoWrapper>
        <PlayerOverlayCard
          playerController={playerController}
          videoWatched={videoWatched}
          onComplete={onComplete}
          onExit={onExit}
          onSkip={onSkip}
          poster={poster}
          title={title}
        />
        <div className="progress-bar">
          <ProgressBar player={playerController} />
        </div>
        <video
          ref={videoElementRef}
          controls={false}
          autoPlay={false}
          disablePictureInPicture={true}
          controlsList="nodownload"
          playsInline
        />
      </VideoWrapper>
    </Backdrop>
  );
};

import { Story } from '@core/models/story-manager/story';
import { AppFactory } from '@app/app-factory';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { createLogger } from '@common/log';
import { achieve, AchievementKey } from '@app/onboarding/achievements';

const log = createLogger('mirate-onboarding-interstitials');

export function useInterstitialMigration({ story }: { story: Story }) {
  const { userManager, storyManager } = AppFactory.root;
  // log.debug(
  //   `isDismissed(onboardingInitiated): ${OnboardingService.instance.isDismissed(
  //     'onboardingInitiated'
  //   )}`
  // );
  log.debug(
    `isDismissed(onboardingComplete): ${OnboardingService.instance.isDismissed(
      'onboardingComplete'
    )}`
  );
  log.debug(`loggedInAndReady: ${userManager.loggedInAndReady}`);
  log.debug(
    `furthestPoint.iteration: ${story.progress.furthestPoint.iteration}`
  );

  if (story.slug !== storyManager.onboardingStorySlug) {
    return;
  }

  const all: AchievementKey[] = [
    'video:listening-warmup',
    'action:revealed-first-sb',
    'video:study-mode',
    'action:play-in-study-mode',
    'action:completed-study',
    'video:natural-listen',
    'action:play-in-listen-mode',
    'action:completed-listen',
    'video:recap',
    'action:chapter-completed',
  ];

  if (OnboardingService.instance.isOnboardingComplete) {
    all.map(key => achieve(key));
    return;
  }

  if (!OnboardingService.instance.isOnboardingComplete) {
    if (story.firstChapter.completedSoundbitesCount > 0) {
      achieve('video:listening-warmup');
      achieve('action:revealed-first-sb');
    }

    if (story.progress.furthestPoint.iteration > 1) {
      achieve('video:study-mode');
      achieve('action:play-in-study-mode');
      achieve('action:completed-study');
    }

    // if (
    //   !userManager.loggedInAndReady /* ||
    //     !onboardingService.isDismissed('storyInterstitial') */
    // ) {
    //   return BeforeAuthInterstitial;
    //   // return <StoryInterstitial story={story} />;
    // }
    if (story.progress.furthestPoint.iteration > 2) {
      achieve('video:natural-listen');
      achieve('action:play-in-listen-mode');
      achieve('action:completed-listen');
    }
  }

  // if (
  //   didAchieve([
  //     'video:listening-warmup',
  //     'action:revealed-first-sb',
  //     'video:study-mode',
  //     'action:play-in-study-mode',
  //     'video:natural-listen',
  //     'action:play-in-listen-mode',
  //     'video'
  //   ])
  // ) {
  // }
}

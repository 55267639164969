import Dayjs from 'dayjs';
import { applySnapshot } from 'ts-state-tree/tst-core';
import { appConfig } from 'app/env';
import { createLogger } from 'app/logger';
import { track } from 'app/track';
import * as meta from 'common/analytics/meta-analytics';
import { AccountData } from './account-data';
import { Root } from '../root';
import { getBaseRoot } from '../app-root';
import { LocaleCode } from '@utils/util-types';
import { AppFactory } from '@app/app-factory';
import { bugsnagNotify } from '@app/notification-service';
import { notEmpty } from '@utils/conditionals';
import { formatMoney } from '@cas-shared/format-money';
import {
  Currency,
  DiscountScheme,
  BillingInterval,
  PricingLevel,
} from '@cas-shared/cas-types';
import {
  getDiscountByAffiliate,
  getDiscountBySlug,
} from '@cas-shared/discount-data';
import { NodeAccountData } from './node-account-data';
import { EntitlementData } from './entitlement-data';
// import { Plan } from '@cas-shared/plan';
// import { getPlans } from '@cas-shared/plan-data';
import { UserManager } from './user-manager';
import { UserData } from './user-data';
import { Plan } from '@cas-shared/plan';
import __ from 'core/lib/localization';
import { l2AccountUrl } from 'components/nav/path-helpers';
import { Classroom } from './classroom';

const log = createLogger('user-manager');

export type MembershipStatus =
  | 'trial'
  | 'full-auto-renew'
  | 'full-no-renew'
  | 'group-access'
  | 'expired'
  | 'paused'
  | 'suspended';

export class Membership {
  l2: LocaleCode;
  userManager: UserManager;

  constructor(userManager: UserManager, l2: LocaleCode) {
    this.userManager = userManager;
    this.l2 = l2;
  }

  get root(): Root {
    return getBaseRoot(this.userManager);
  }

  get accountData(): AccountData {
    return this.userManager.accountData;
  }

  get nodeAccountData(): NodeAccountData {
    return this.userManager.nodeAccountData;
  }

  get userData(): UserData {
    return this.userManager.userData;
  }

  get apiInvoker() {
    return this.userManager.apiInvoker;
  }

  get dump() /*: MembershipData*/ {
    const nodeEntitlement = this.nodeEntitlement;
    return {
      l2: this.l2,
      productName: this.root.productNameForL2(this.l2),
      statusKey: this.membershipStatus,
      // statusDisplay: this.membershipDisplayL2(l2),
      membershipTypeDisplay: this.membershipTypeDisplay,
      fullAccess: this.fullAccess,
      autoRenew: this.autoRenew,
      accessExpired: this.accessExpired,
      hadFullAccess: this.hadFullAccess,
      autoRenewInterval: this.autoRenewInterval,
      autoRenewAmount: this.autoRenewAmount,
      autoRenewCurrency: this.autoRenewCurrency,
      autoRenewAmountDisplay: this.autoRenewAmountDisplay,
      showAccountPageCheckout: this.showAccountPageCheckout,
      remainingFullAccessInDays: this.remainingFullAccessInDays,
      showSwitchPlanInterval: this.showSwitchPlanInterval,
      fullAccessUntil: this.fullAccessUntil,
      remainingFullAccessDays: this.remainingFullAccessInDays,
      autoRenewFailed: this.autoRenewFailed,
      paymentFailureMessage: this.paymentFailureMessage,
      licensedClassroomLabel: this.licensedClassroomLabel,
      discountScheme: this.discountScheme, // l2 agnostic
      // properties only relevant to node entitlements
      pausedUntil: nodeEntitlement?.pausedUntil,
      billingResumesOn: nodeEntitlement?.billingResumesOn,
      // only relevant to legacy rails entitlements
      // hasApplePaidAccess: this.accountData.hasApplePaidAccess,
    };
  }

  // deprecated
  get membershipStatus(): MembershipStatus {
    return this.statusKey;
  }

  get statusKey(): MembershipStatus {
    const nodeState = this.nodeEntitlement;
    const accountDataState = this.accountData
      .membershipState as MembershipStatus;
    if (accountDataState === 'group-access') {
      // assume licensed if joined classroom exists for given l2
      const licensed = this.accountData.hasJoinedClassroomsForL2(this.l2);
      if (licensed) {
        return 'group-access';
      }
      // if (!nodeState) {
      //   // handles the trial ES state when licensed for EN product
      //   return 'trial';
      // }

      // beware that the EN group access state gets incorrectly
      // migrated as ES access by the rails side logic, but we're never
      // expecting a real user to have to both products right now,
      // so force the non-licensed product to 'trial' for now
      return 'trial';
    }

    const { autoRenew } = this;
    if (nodeState) {
      const { accessUntil, pausedUntil } = nodeState;
      if (
        pausedUntil &&
        this.root.storyManager.isTodaySameOrBefore(Dayjs(pausedUntil))
      ) {
        return 'paused';
      }

      const fullAccess =
        accessUntil &&
        // todo: this date logic technically belongs in the server, but this is convenient for testing
        this.root.storyManager.isTodaySameOrBefore(Dayjs(accessUntil));
      if (fullAccess) {
        return autoRenew ? 'full-auto-renew' : 'full-no-renew';
      } else {
        if (autoRenew) {
          return 'suspended';
        }
        return !!accessUntil ? 'expired' : 'trial';
      }
    } else {
      // legacy rails entitlement logic

      if (this.l2 !== 'es') {
        // must be EN catalog w/o node entitlement
        return 'trial';
      }

      const { fullAccess, autoRenew, fullAccessUntil } = this.accountData;
      // const accountDataState = this.accountData .membershipState as MembershipStatus;

      if (fullAccess) {
        return autoRenew ? 'full-auto-renew' : 'full-no-renew';
      } else {
        if (autoRenew) {
          return 'suspended';
        }
        return !!fullAccessUntil ? 'expired' : 'trial';
      }
    }
  }

  get membershipTypeDisplay(): string {
    switch (this.membershipStatus) {
      case 'trial':
        return __('Trial', 'trial'); // probably never displayed now
      case 'expired':
        return __('Expired', 'expired');
      case 'full-auto-renew':
        return this.subscriptionInterval;
      case 'paused':
        return __('Paused', 'paused'); // probably qualify once actually exposed
      case 'suspended':
        return __('Suspended', 'suspended') + ` (${this.subscriptionInterval})`;
      case 'full-no-renew':
        return __('Full access (no auto-renew)', 'fullAccessNoAutoRenew');
      case 'group-access':
        return __('Group access', 'groupAccess');
      default:
        return this.membershipStatus; // unexpected, should probably log error
    }
  }

  get subscriptionInterval(): string {
    const interval = this.autoRenewInterval;
    switch (interval) {
      case 'month':
        return __('Monthly subscription', 'monthlySubscription');
      case 'year':
        return __('Yearly subscription', 'annualSubscription');
      case 'day':
        return 'Daily subscription'; // internal testing only, doesn't need localizing
      default:
        return undefined;
    }
  }

  get fullAccess(): boolean {
    const statusKey = this.statusKey;
    return ['full-auto-renew', 'full-no-renew', 'group-access'].includes(
      statusKey
    );
  }

  get autoRenew(): boolean {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return !!nodeState.stripeSubscriptionId;
    } else {
      if (this.l2 === 'es') {
        return this.accountData.autoRenew;
      } else {
        return false;
      }
    }
  }

  get accessExpired(): boolean {
    return !this.fullAccess && this.hadFullAccess;
  }

  get hadFullAccess() {
    return notEmpty(this.fullAccessUntil);
  }

  get autoRenewAmountDisplay(): string {
    return this.formatAutoRenewAmount();
  }

  formatAutoRenewAmount({
    qualified = true,
  }: { qualified?: boolean } = {}): string {
    const amount = this.autoRenewAmount;
    const currrency = this.autoRenewCurrency;
    return amount ? formatMoney(amount, currrency, { qualified }) : '';
  }

  get autoRenewAmount(): number {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      const price = Number(nodeState.price);
      if (nodeState.percentOff) {
        return price * (1 - nodeState.percentOff / 100);
      } else {
        return price;
      }
    } else {
      const amount = this.accountData.paymentData?.autoRenewAmount;
      return amount;
    }
  }

  get autoRenewInterval(): BillingInterval {
    if (!this.autoRenew) {
      return undefined;
    }

    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return nodeState.billingInterval;
    } else {
      // legacy rails subscriptions assumed to be monthly
      return 'month';
    }
  }

  get autoRenewCurrency(): Currency {
    // only one currency can be used per account
    return this.nodeAccountData?.currency || 'usd';
  }

  get hasSpecialPricing(): boolean {
    return this.pricingLevel !== 'retail';
  }

  get hasClassroomPricing(): boolean {
    return (
      this.accountData.pricingGroup === 'edu' ||
      this.joinedClassrooms.some(c => c.classroomPricing)
    );
  }

  get joinedClassrooms(): Classroom[] {
    return this.accountData.joinedClassroomsForL2(this.l2);
  }

  // only offer discount for never-subscribed users. discount abandoned if subscription cancelled
  get discountDisallowed(): boolean {
    if (this.membershipStatus !== 'trial') {
      return true;
    }
    if (this.hasClassroomPricing) {
      return true;
    }
    return false;
  }

  // todo: should probably rename to `resolveDiscountScheme`
  get affiliateDiscountScheme(): DiscountScheme {
    if (this.discountDisallowed) {
      return undefined;
    }

    const { l1 } = this.root;
    const { affiliateDiscountSlug, affiliateSlug, affiliateL1 } =
      this.accountData;
    if (affiliateDiscountSlug) {
      const discount = getDiscountBySlug({
        discountSlug: affiliateDiscountSlug,
        l1,
      });
      if (discount) {
        return discount;
      }
    }

    // deprecated flow below

    // honor affiliate L1 scoping
    if (affiliateL1 && affiliateL1 !== l1) {
      return undefined;
    }
    return getDiscountByAffiliate({ affiliateSlug, l1 });
  }

  // either percentage discount or aff
  get hasAffiliateDerivedPricing(): boolean {
    if (this.discountDisallowed) {
      return false;
    }
    // const { affiliateL1, pricingGroup } = this.accountData;
    // if (pricingGroup === 'aff' || this.accountData.affiliateDiscountSlug === 'AFF') {
    //   if (!affiliateL1 || affiliateL1 === this.root.l1) {
    //     return true;
    //   }
    // }
    if (this.showConcessionPricing) {
      return true;
    }

    if (this.pricingLevel !== 'retail') {
      return false; // for now, never stack affiliate discount with other reduced pricing
    }
    // treat absence of an assigned pricing label to mean no discount (attribution only)
    // todo: should change non-discount affiliate to an explicit rails managed flag
    // if (!affiliatePricingLabel) {
    //   return false;
    // }
    return !!this.affiliateDiscountScheme;
  }

  get showConcessionPricing(): boolean {
    if (this.discountDisallowed) {
      return false;
    }
    const { affiliateL1, affiliateDiscountSlug, pricingGroup } =
      this.accountData;
    if (pricingGroup === 'aff' || affiliateDiscountSlug === 'AFF') {
      if (!affiliateL1 || affiliateL1 === this.root.l1) {
        return true;
      }
    }
    return false;
  }

  // get showAffiliateBanner(): boolean {
  //   return this.hasAffiliateDerivedPricing && !affiliatePricingLabel
  // }

  // todo: consider refactoring to resolve affiliate props at membership level
  // get affiliateSlug(): string { return this.accountData.affiliateSlug; }
  // affiliateWelcomeHeading?: string = null;
  // affiliatePricingLabel?: string = null;

  // get showAffiliatePricing(): boolean {
  //   // affiliate linked to percentage discount instead of concession pricing
  //   if (this.affiliateDiscount) {
  //     return false;
  //   }

  //   if (this.accountData.hasAffiliatePricing) {
  //     return true;
  //   }
  //   // needed for the anonymous pricing cards
  //   const railsPlan = this.accountData.plans?.[0];
  //   return railsPlan && railsPlan.serverSlug.endsWith('-aff');
  // }

  get pricingLevel(): PricingLevel {
    if (this.hasClassroomPricing) {
      return 'classroom';
    }
    if (this.accountData.isStudent) {
      // stu_2020, stu_2021 or edu pricing groups
      return 'student';
    }
    if (this.showConcessionPricing) {
      return 'concession';
    }
    return 'retail';
  }

  get hasCustomPricingLabel(): string {
    return (
      this.hasAffiliateDerivedPricing && this.accountData.affiliatePricingLabel
    );
  }

  get pricingDescription(): string {
    // always use the affiliatePricingLabel if it is assigned
    if (this.hasCustomPricingLabel) {
      return this.accountData.affiliatePricingLabel;
    }
    switch (this.pricingLevel) {
      case 'classroom':
        return __('Classroom discount', 'classroomDiscount');
      case 'student':
        return __('Student discount', 'studentDiscount');
      case 'concession':
        return __('Select discount', 'selectDiscount');
      case 'retail':
      default:
        return undefined;
    }
  }

  // get affiliatePricingDescription(): string {
  //   const railsPlan = this.accountData.plans?.[0];
  //   if (railsPlan && railsPlan.serverSlug.endsWith('-aff')) {
  //     return railsPlan.pricingDescription;
  //   }
  //   return undefined;
  // }

  get discountScheme(): DiscountScheme {
    const { l1 } = this.root;

    // first check affiliate associate discount
    const candidate1 = this.affiliateDiscountScheme;
    if (candidate1) {
      return candidate1;
    }

    const discountSlug = this.nodeAccountData?.discountSlug;
    if (!!discountSlug) {
      // todo: honor expiry
      return getDiscountBySlug({ discountSlug, l1 });
    }

    return undefined;
  }

  get checkoutPercentOff(): number {
    const scheme = this.discountScheme;
    if (scheme) {
      return scheme.percentOff || 0;
    } else {
      return 0;
    }
  }

  // will expose the checkout flow for full-access w/o auto-renew from account screen
  get showAccountPageCheckout() {
    if (this.autoRenew || this.userManager.purchaseFlowDisabled) {
      return false;
    }

    const daysOfFullAccess = this.remainingFullAccessInDays;
    // avoid potential trial period barfage if too far out
    if (daysOfFullAccess > 370) {
      return false;
    }

    // omit the checkout flow for the non-active product
    if (this.l2 !== this.root.l2) {
      return false;
    }

    return true;
  }

  get remainingFullAccessInDays() {
    const { today } = this.root;
    const fullAccessUntil = this.fullAccessUntil;
    if (fullAccessUntil) {
      const untilDayjs = Dayjs(fullAccessUntil);
      if (untilDayjs.isAfter(today)) {
        const days = untilDayjs.diff(today, 'day');
        log.debug(`remainingFullAccessInDays: ${days}`);
        return days;
      }
    }
    return 0;
  }

  get stripeTrialActive(): boolean {
    const nodeState = this.nodeEntitlement;
    if (nodeState && !!nodeState.stripeTrialUntil) {
      const trailUntilDayjs = Dayjs(nodeState.stripeTrialUntil);
      return this.root.storyManager.isTodaySameOrBefore(trailUntilDayjs);
    }
    return false;
  }

  get showSwitchPlanInterval(): boolean {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      if (this.stripeTrialActive) {
        // trial status throws a wrench into the switch plan behavior, so just avoid dealing with
        return false;
      }

      if (this.root.l2 !== this.l2) {
        // never show for the non-active product
        return false;
      }

      if (this.userManager.hasGrandfatheredPricing !== false) {
        return false; // will transiently return false if undefined and still loading data
      }

      return (
        this.statusKey === 'full-auto-renew' &&
        nodeState.billingInterval === 'month'
      );
    } else {
      return false;
    }
  }

  get fullAccessUntil(): string {
    const status = this.statusKey;
    if (status === 'trial') {
      return undefined;
    }

    const nodeState = this.nodeEntitlement;
    return nodeState?.accessUntil || this.accountData.fullAccessUntil;

    // if (nodeState) {
    //   return nodeState.accessUntil;
    // } else {
    //   if (this.l2 === 'es') {
    //     return this.accountData.fullAccessUntil;
    //   } else {
    //     return undefined;
    //   }
    // }
  }

  get pausedUntil(): string {
    return this.nodeEntitlement?.pausedUntil;
  }

  get billingResumesOn(): string {
    return this.nodeEntitlement?.billingResumesOn;
  }

  get autoRenewFailed(): boolean {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return !!nodeState.failureMessage || !!nodeState.failureCode;
    } else {
      if (this.l2 === 'es') {
        return !!this.accountData.paymentData?.autoRenewFailed;
      } else {
        return false;
      }
    }
  }

  get paymentFailureMessage(): string {
    const nodeState = this.nodeEntitlement;
    if (nodeState) {
      return nodeState.failureMessage || nodeState.failureCode;
    } else {
      return undefined; // message not available for legacy subscriptions
    }
  }

  get licensedClassroomLabel(): string {
    // todo: this logic should be tightened up, but that will likely require rails-side changes
    // and this should be sufficiet for any real situations for the foreseeable future
    if (this.fullAccess) {
      return this.accountData.licensedClassroomLabel;
    } else {
      return undefined;
    }
  }

  get showPriceIncreaseBanner(): boolean {
    if (this.userData.userSettings.messageIsDismissed('price-increase-2024')) {
      return false;
    }

    return this.showPriceIncreaseInlineNotice;
  }

  get showPriceIncreaseInlineNotice(): boolean {
    if (!Boolean(appConfig.priceIncreaseDate)) {
      return false;
    }

    if (!this.showAccountPageCheckout) {
      return false;
    }

    if (this.l2 !== 'es') {
      return false;
    }

    const statusKey = this.membershipStatus;

    if (
      statusKey === 'trial' ||
      statusKey === 'full-no-renew' ||
      statusKey === 'expired' ||
      statusKey === 'suspended'
    ) {
      return true;
    }

    return false;
  }

  dismissPriceIncreaseAnnouncement(): void {
    this.userData.userSettings.dismissMessage('price-increase-2024');
  }

  get hasNodeEntitlement(): boolean {
    return !!this.nodeEntitlement;
  }

  get nodeEntitlement(): EntitlementData {
    // const { l2 } = this.root;
    // const nodeState = this.nodeAccountData?.entitlements?.[l2];
    const nodeState = this.nodeAccountData?.entitlements?.get(this.l2);
    return nodeState;
  }

  async initiateCheckout(plan: any /* Plan - todo: fix schema gen */) {
    const { slug: planSlug, l2, l1 } = plan;
    const successUrl = window.checkoutSuccessUrlFn();
    const cancelUrl = window.checkoutCancelUrlFn();

    log.info(`nodeInitiateCheckout - successUrl: ${successUrl}`);
    track('stripe__initiate_checkout', { plan: plan.slug });
    meta.trackEvent('stripe__initiate_checkout', { plan: plan.slug });

    if (this.autoRenew) {
      // todo: confirm UX if we somehow hit this
      throw Error(
        'Unexpected attempt to resubscribe with an existing subscription'
      );
    }

    const trialDays = this.remainingFullAccessInDays;
    const discountSlug = this.discountScheme?.slug;

    const result = await AppFactory.caliServerInvoker.initiateCheckout({
      uuid: this.accountData.userDataUuid,
      planSlug,
      discountSlug, // client-side override needed for affiliate associated discount
      l1,
      l2,
      trialDays,
      successUrl,
      cancelUrl,
    });

    // todo: include new account data in api result
    await this.userManager.refreshNodeAccountData();
    return result;
  }

  async createStripePortalSession(): Promise<{ url: string }> {
    let result: { url: string };
    if (this.hasNodeEntitlement) {
      const returnUrl = l2AccountUrl(this.l2);

      const uuid = this.accountData.userDataUuid;

      const { l1 } = this.root;
      result = await AppFactory.caliServerInvoker.createPortalSession({
        uuid,
        l1, // not relevant
        l2: this.l2,
        returnUrl,
      });
    } else {
      result = await this.createRailsStripePortalSession();
    }
    return result;
  }

  async createRailsStripePortalSession() {
    log.info(`createStripePortalSession`);
    const returnUrl = l2AccountUrl(this.l2);
    const result = await this.apiInvoker.post<{
      url: string;
    }>('users/create_stripe_portal_session', {
      return_url: returnUrl,
    });

    // console.log(result);
    return result;
  }

  async cancelAutoRenew({
    ignoreError = false,
  }: { ignoreError?: boolean } = {}) {
    let result: any;
    if (this.hasNodeEntitlement) {
      result = await this.cancelNodeSubscription();
    } else {
      result = await this.cancelRailsAutoRenew({ ignoreError });
    }
    this.userManager.persistLocal().catch(bugsnagNotify);
    return result;
  }

  async cancelNodeSubscription(): Promise<{ message: string }> {
    // const { l2 } = this.root;
    log.info(`cancelNodeSubscription - l2: ${this.l2}`);

    // todo: adapt to either old or new stripe implementation for existing subscription

    // todo: tracking
    // track('stripe__initiate_checkout', { plan: plan.slug });

    const result = await AppFactory.caliServerInvoker.cancelSubscription({
      uuid: this.accountData.userDataUuid,
      l2: this.l2,
    });
    const { status, accountData } = result;

    // await this.refreshNodeAccountData();
    if (status === 'success') {
      // this.nodeAccountData = accountData;
      applySnapshot(this.nodeAccountData, accountData || {});
      // if (this.fullAccess) {
      //   return { message: __('') };
      // } else {
      //   return { message: __('') };
      // }

      // null message will bypass the toast. shouldn't be needed now that we have the exit survey screen
      return { message: null };
    } else {
      // unexpected flow. any stripe or network error should result in an exception
      const message = `cancelNodeSubscription - unexpected result - userId: ${
        this.accountData.userId
      }, status: ${String(status)}`;
      log.error(`${message} - ${JSON.stringify(result)}`);
      bugsnagNotify(message);
    }
  }

  async cancelRailsAutoRenew({ ignoreError = false } = {}) {
    ignoreError = ignoreError || appConfig.stripe.ignoreCancelAutoRenewErrors;
    log.info(`cancelRailsAutoRenew - ignoreError: ${ignoreError}`);
    track('account__cancel_auto_renew');
    const result = await this.apiInvoker.post('users/cancel_auto_renew', {
      ignoreError,
    });
    const { /*message,*/ accountData } = result;

    await this.userManager.applyNewAccountData(accountData, {});
    return result;
  }

  // internal / testing
  async forceExpireAccess() {
    const result = await AppFactory.caliServerInvoker.forceExpireAccess({
      uuid: this.accountData.userDataUuid,
      l2: this.l2,
    });
    // could apply the result.accountData directly if refactored
    await this.userManager.refreshNodeAccountData();
    return result;
  }

  async resolveNodePlan(interval: BillingInterval): Promise<Plan> {
    const plans = await this.userManager.resolveNodePlans(this.l2);
    return plans.find(plan => plan.interval === interval);
  }

  async switchToYearlyData() {
    // const plans = await this.userManager.resolveNodePlans(this.l2);
    // const yearlyPlan = plans.find(plan => plan.interval === 'year');
    const yearlyPlan = await this.resolveNodePlan('year');
    if (!yearlyPlan) {
      throw new Error('Yearly plan not found');
    }

    const newPlanSlug = yearlyPlan.slug;
    if (this.autoRenewInterval !== 'month') {
      throw Error('Not on a monthly plan');
    }

    const oldAnnualPrice = this.autoRenewAmount * 12;
    const newPrice = yearlyPlan.price;

    const currency = yearlyPlan.currency;
    const savedPercent = Math.round(
      (100 * (oldAnnualPrice - newPrice)) / oldAnnualPrice
    );

    // beware, this is an estimate used by the client ui
    // server calculation is derived from the stripe subscription response data
    // const newAccessUntil = dayjsToIsoDate(
    //   Dayjs(this.fullAccessUntil).add(11, 'month')
    // );
    const newAccessUntil = this.fullAccessUntil; // doesn't actually change

    const invoiceData = await this.previewSwitchPlanInterval();
    log.info(
      `switchToYearlyData - invoiceData: ${JSON.stringify(invoiceData)}`
    );
    const { amountDue, newPeriodEndIsoDate } = invoiceData;

    return {
      amountDue,
      newPeriodEndIsoDate,
      newPlanSlug,
      newPrice,
      currency,
      newAccessUntil,
      savedPercent,
    };
  }

  async previewSwitchPlanInterval() {
    const { l1, l2 } = this.root;
    log.info(`switchPlanInterval - l2: ${l2}`);

    const result = await AppFactory.caliServerInvoker.previewSwitchPlanInterval(
      {
        uuid: this.accountData.userDataUuid,
        l1,
        l2,
        newInterval: 'year',
        pricingLevel: this.pricingLevel,
      }
    );

    // todo: figure out why applying the result account data doesn't work as needed
    // this.userManager.applyNodeAccountData(result.accountData);
    await this.userManager.refreshNodeAccountData();
    return result;
  }

  async switchPlanInterval() {
    const { l1, l2 } = this.root;
    log.info(`switchPlanInterval - l2: ${l2}`);

    const result = await AppFactory.caliServerInvoker.switchPlanInterval({
      uuid: this.accountData.userDataUuid,
      l1,
      l2,
      newInterval: 'year',
      pricingLevel: this.pricingLevel,
    });

    // todo: figure out why applying the result account data doesn't work as needed
    // this.userManager.applyNodeAccountData(result.accountData);
    await this.userManager.refreshNodeAccountData();
    this.userManager.persistLocal().catch(bugsnagNotify);
    return result;
  }

  //
  // future functionality
  //
  async pauseSubscription({ months }: { months: number }) {
    const { storyManager } = this.root;
    const effectiveDate = storyManager.currentDate;
    log.info(`pauseSubscription - l2: ${this.l2}`);

    // const days = months * 30; // todo: use dayjs to accurately count out the months
    const result = await AppFactory.caliServerInvoker.pauseSubscription({
      uuid: this.accountData.userDataUuid,
      l2: this.l2,
      months,
      effectiveDate,
    });

    await this.userManager.refreshNodeAccountData();
    this.userManager.persistLocal().catch(bugsnagNotify);
    return result;
  }

  async resumeSubscription() {
    const { storyManager } = this.root;
    const effectiveDate = storyManager.currentDate;
    log.info(
      `resumeSubscription - l2: ${this.l2}, effectiveDate: ${effectiveDate}`
    );

    const result = await AppFactory.caliServerInvoker.resumeSubscription({
      uuid: this.accountData.userDataUuid,
      l2: this.l2,
      effectiveDate, // allows honoring the date override when testing
    });

    await this.userManager.refreshNodeAccountData();
    this.userManager.persistLocal().catch(bugsnagNotify);
    return result;
  }
}

import deepmerge from 'deepmerge';
import { config as baseConfig } from './base';

//
// my.devtest.jiveworld.app
//
// continuous deployment from develop
// also shared by:
//   pwa.devtest.jiveworld.app (nps deploy.pwa.devtest)
//

export const config = deepmerge(baseConfig, {
  apiEnv: 'devtest',

  // priceIncreaseDate: 'April 30',

  // enable/disable via spa.vite.config.ts
  // i18n: {
  // },

  analytics: {
    mixpanel: {
      // mixpanel project: internal (black/green)
      projectToken: '5f46a76541c8fe1dc3a65f451180b8e9',
      // debug: true,
    },

    // segmentWriteKey: 'HP0iVsUQB6F9CawQ6uVEZLD9kpcrZcJ3', // lupa-spa (testing)

    // property: jiveworld devtest, stream name: my.devtest.jiveworld.com
    googleAnalyticsKey: null, //'G-PSM9E3F4ZF',
  },

  logging: {
    bufferLogger: {
      bufferLineLimit: 10000, // extra generous buffer for the test build
      nativeRelayActivated: true,
    },
    // minimumLogLevel: logLevels.debug,
  },

  // updateCheckIntervalMs: 1 * 60 * 1000, // 1 min check interval for devtest and preview deploys
  updateCheckIntervalMs: 30 * 1000, // temporarily set to 30 seconds

  // env.js defaults fine for devtest
  // invariants: {
  //   log: true, // simple console log
  //   report: true, // to bugsnag
  //   alert: true, // attempt red toast
  //   throw: false, // render error
  // },

  showUnhandledErrors: true,

  // website: {
  //   urls: {
  //     marketingSite: 'https://lupa.devtest.jiveworld.com',
  //   },
  // },

  player: {
    debug: true, // turns on the '~' debug mode by default when either study or soundbite player is loaded
    enableOldIosScrollFix: true,
  },

  // soundbites: {},
});

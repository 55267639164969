import { pickLocalizedValue } from '@core/lib/localization';
import { AppFactory } from '@app/app-factory';

const poster = 'natural-listening-poster.svg';
// for now we have only the one video asset
const videoId = '23b5ad50f8a961f8451f35036d3a8d04';
const shortVideoId = '02da09a020b0c7e6a5f7c5503cbbd7c4';

const en = {
  videoId,
  poster,
};

const pt = {
  videoId,
  poster,
};

const es = {
  videoId,
  poster,
};

export function getVideoAssets() {
  const result = pickLocalizedValue({ en, pt, es });
  const { shortVideosEnabled } = AppFactory.root.localState;
  if (shortVideosEnabled) {
    result.videoId = shortVideoId;
  }
  return result;
}

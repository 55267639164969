import deepmerge from 'deepmerge';
import { config as baseConfig } from './base-devtest';

//
// test.jiveworld.app
//
// nps deploy.test
//

export const config = deepmerge(baseConfig, {
  // showUnhandledErrors: true,

  // authShortCodeEnabled: false,

  // priceIncreaseDate: 'April 30', // tentataive

  analytics: {
    // mixpanelNode: {
    //   enabled: false, // exploratorily turn off to evaluate impact to startup dynamics
    // },
    googleAnalyticsKey: 'G-PSM9E3F4ZF',
  },

  website: {
    // urls: {
    //   marketingSite: 'https://www.staging.jiveworld.app',
    // },
    // allows sharing of jw-traffic-source data to rails api
    cookieDomain: '.test.jiveworld.app',
  },

  lambdaFunctions: {
    enabled: true,
  },

  // catalogs: {
  //   en: {
  //     pt: 'english-pt', // override for now so test can be a preview of the brasil deployment catalog
  //   },
  // },
});

import { pickLocalizedValue } from '@core/lib/localization';
import { AppFactory } from '@app/app-factory';

const poster = 'study-mode-poster.svg';

// for now we have only the one video asset
const videoId = '17c4006380d747b2233df46bb0efabd4';
const shortVideoId = 'afec42967a99c176e8229cf330893c02';

const en = {
  videoId,
  poster,
};

const pt = {
  videoId,
  poster,
};

const es = {
  videoId,
  poster,
};

export function getVideoAssets() {
  const result = pickLocalizedValue({ en, pt, es });
  const { shortVideosEnabled } = AppFactory.root.localState;
  if (shortVideosEnabled) {
    result.videoId = shortVideoId;
  }
  return result;
}

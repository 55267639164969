import { pickLocalizedValue } from '@core/lib/localization';
// import { getVideoSupportAssetUrl } from 'components/ui/video-player/player-view-controller';
import { AppFactory } from '@app/app-factory';

const poster = 'listening-warmup-poster.svg';

//storage.googleapis.com/jw-pub/spa/video/listening-warmup-poster.png

// for now we have only the one video asset
const videoId = '25bdcbaaf2cac209860305d9014e6d2b';
const shortVideoId = 'b454360a9a662a8894aef7ae7ccf8df1';

const en = {
  videoId,
  poster,
};

const pt = {
  videoId,
  poster,
};

const es = {
  videoId,
  poster,
};

export function getVideoAssets() {
  const result = pickLocalizedValue({ en, pt, es });
  const { shortVideosEnabled } = AppFactory.root.localState;
  if (shortVideosEnabled) {
    result.videoId = shortVideoId;
  }
  return result;
}

import * as React from 'react';
import { Channel } from '@core/models/story-manager/channel';
import { createLogger } from '@common/log';
import { Image } from '@naan/primitives/image';
import { styled } from '@naan/stitches.config';

import mothImage from 'components/learn/dashboard/assets/channel-thumb-the-moth.png';
import truthImage from 'components/learn/dashboard/assets/channel-thumb-the-truth.png';
import radiolabImage from 'components/learn/dashboard/assets/channel-thumb-radiolab.png';
import talImage from 'components/learn/dashboard/assets/channel-thumb-this-american-life.png';

const log = createLogger('channel-badge');

const brandImageMap = {
  moth: mothImage,
  truth: truthImage,
  radiolab: radiolabImage,
  tal: talImage,
};

export const getChannelImage = (channel: Channel): string | null => {
  return brandImageMap[channel.slug as keyof typeof brandImageMap] ?? null;
};

const AvatarImage = styled(Image, {
  width: '$$size',
  height: '$$size',
  borderRadius: 8,
  size: 'contain',
  background: '$colors$black-alpha-10',
  alignSelf: 'center',
  variants: {
    size: {
      small: {
        $$size: '72px',
      },
      medium: {
        $$size: '144px',
      },
      large: {
        $$size: '216px',
      },
    },
  },
});

type AvatarImageProps = React.ComponentProps<typeof AvatarImage>;

export const ChannelAvatar = ({
  channel,
  ...props
}: { channel: Channel } & AvatarImageProps) => {
  const imageSrc = getChannelImage(channel);

  if (!imageSrc) {
    log.info(`image for channel ${channel.slug} not found`);
    return null;
  }

  return <AvatarImage src={imageSrc} {...props} />;
};

ChannelAvatar.toString = () => `${AvatarImage}`;

import { pickLocalizedValue } from '@core/lib/localization';

const poster = 'vocab-review-poster.svg';

// for now we have only the one video asset
const videoId = '556a13523d25a08dd955f3d9274a6a93';

const en = {
  videoId,
  poster,
};

const pt = {
  videoId,
  poster,
};

const es = {
  videoId,
  poster,
};

export function getVideoAssets() {
  return pickLocalizedValue({ en, pt, es });
}

import * as Dialog from '@naan/primitives/modals/dialog';
import * as React from 'react';
import { PlainMarkdown } from '@naan/primitives/text';
import { styled } from '@naan/stitches.config';
import { useNavigate } from 'react-router-dom';
import { learnHomePath, subscribePath } from 'components/nav/path-helpers';
import { AppFactory } from '@app/app-factory';
import { Image } from '@naan/primitives/image';
import { CompletedCheckmark } from 'story/chapter-list-item/components/completed-checkmark';

import { LocaleCode } from '@utils/util-types';
import { useCurrentStory } from 'components/story-detail/current-story-context';
import { observer } from 'mobx-react';
import {
  isDismissed,
  OnboardingService,
} from '@app/onboarding/onboarding-service';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';

import __, { translateWithoutDefault } from '@core/lib/localization';

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 16,
  '& .figure': {
    display: 'flex',
    justifyContent: 'center',
    margin: '16px 0 8px',
    width: 144,
    height: 144,
    borderRadius: 8,
    overflow: 'hidden',
    position: 'relative',
    '& .thumbnail': {
      width: 144,
      height: 144,
    },
    '& .checkmark-container': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: 144,
      height: 144,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0,0,0,.05)',
      '& svg': {
        width: 56,
        height: 56,
      },
    },
  },
  '& h2': {
    textStyle: 'small-heading',
    width: '100%',
  },
  '& .copy': {
    textStyle: 'body',
    '& p': {
      marginBottom: 16,
    },
  },
});

const endOfStoryCtaBody = (l2: LocaleCode) => {
  //   switch (l2) {
  //     case 'en':
  //       return __(
  //         `Don't stop now — unlock full-length English stories designed to help you achieve true fluency...`,
  //         'endOfStoryCtaBody.en'
  //       );
  //     default:
  //       return __(
  //         `Don't stop now — unlock full-length Spanish stories designed to help you achieve true fluency.

  // * 50+ hours of real, human stories from across Latin America
  // * 100+ Soundbites — mini-lessons that dive into language and culture`,
  //         'dontStopNowUnlockFullLengthSpanishStories'
  //       );
  //   }
  return translateWithoutDefault(`cms:endOfStoryCtaBody:${l2}`);
};

// const strings: {
//   title: string;
//   body: string;
// }[] = [
//   {
//     title: __('Congrats, you completed the story!', 'congratsCompletedStory'),
//     body: dontStopNowUnlockCopy('es'),
//   },
// ];

const EndOfStoryCtaDialogContent = observer(() => {
  const { storyManager, l2 } = AppFactory.root;
  // const { title, body } = strings[Math.floor(Math.random() * strings.length)];
  const title = __(
    'Congrats, you completed the story!',
    'congratsCompletedStory'
  );
  const body = endOfStoryCtaBody(l2);
  const story = storyManager.onboardingStory;
  const navigate = useNavigate();

  const handleDismiss = React.useCallback(() => {
    OnboardingService.instance.dismiss('completedStoryCta');
  }, []);

  return (
    <Dialog.Container open onDismiss={handleDismiss}>
      <Dialog.Body>
        <Wrapper>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <div className="figure">
            <Image
              src={story.listImageUrl}
              role="presentation"
              className="thumbnail"
            />
            <div className="checkmark-container">
              <CompletedCheckmark />
            </div>
          </div>
          <h2>{title}</h2>
          <div className="copy">
            <PlainMarkdown source={body} />
          </div>
        </Wrapper>
      </Dialog.Body>
      <Dialog.ButtonsContainer direction={'column'}>
        <Dialog.Button
          onClick={() => {
            navigate(subscribePath());
            handleDismiss();
          }}
          label={__('Subscribe now', 'subscribeNow')}
          presentation={'teal'}
        />
        <Dialog.Button
          onClick={() => {
            navigate(learnHomePath());
            handleDismiss();
          }}
          label={__(
            'Browse all Jiveworld stories',
            'browseAllJiveworldStories'
          )}
          presentation={'grayLight'}
        />
      </Dialog.ButtonsContainer>
    </Dialog.Container>
  );
});

export const EndOfStoryCtaDialog = observer(() => {
  const story = useCurrentStory();
  const { userManager } = AppFactory.root;

  // only applicable for trial users who have just finished the onboarding story
  if (!story.isTheOnboardingStory || !userManager.fullAccess) {
    return null;
  }

  if (!story.completed) {
    return null;
  }

  if (isDismissed('completedStoryCta')) {
    return null;
  }

  // don't show the CTA until after a vocab review is complete
  const { hasDoneVocabReview } = AppFactory.root.userManager.userData;
  if (!hasDoneVocabReview || story.vocabCount > 0) {
    return null;
  }

  // never show while another modal is open
  if (storyDetailUiModel.isShowingModal) {
    return null;
  }

  return <EndOfStoryCtaDialogContent />;
});

import * as React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { AppFactory } from 'app/app-factory';
import { styled } from '@naan/stitches.config';
import { StoryHeader } from 'story/story-header';
import { learnHomePath } from 'components/nav/path-helpers';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { StoryDetailCard } from 'components/story-detail/story-detail-card';
import { StoryOverflowMenu } from 'components/story-detail/story-overflow-menu';
import { StoryDetailCardAssignment } from 'components/stories/story-detail-card-assignment';
import { DownloadControlTemp } from 'components/stories/download-control-temp';
import { Box } from '@naan/primitives/box';
import { bugsnagNotify } from '@app/notification-service';
import { StoryTab } from './story-tab';
import { StoryLockedActions } from 'story/story-locked-actions';
import { useStoryThumbScrollingFx } from '../scrolling-fx';
import { elementIds } from 'components/dom-utils/element-ids';
import { storyDetailUiModel } from 'components/story-detail/story-detail-ui-model';
import { useEnableUpdatePrompt } from '@common/hooks/use-enable-update-prompt';
import { Story } from '@core/models/story-manager';
import { StoryProgressPanel } from './story-progress-panel';
import { StorySubscribeCta } from 'components/learn/dashboard/subscribe-cta';
import { EndOfStoryCtaDialog } from 'components/cta/end-of-story-cta-dialog';

const StoryDetailScreenStylesWrapper = styled('div', {
  $$storyHeaderHeight: '56px',
  $$assignmentBannerHeight: '0px',
  $$tabbarHeight: '0px',
  $$tabbarTop: 'calc($$assignmentBannerHeight + $$storyHeaderHeight)',

  position: 'relative',
  isolation: 'isolate',

  minHeight: 'calc(100vh - $$storyHeaderHeight - $$assignmentBannerHeight )',

  '& > .story-header-container': {
    position: 'sticky',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 99,
    // pull story details up behind header
    marginBottom: '-$$storyHeaderHeight',
  },
  '& .unitHeader': {
    textStyle: 'small-heading',
    marginBottom: '$space$4',
    marginTop: '$space$10',
  },
  '& .unitDuration': {
    textStyle: 'small-text',
    marginLeft: '$space$2',
    color: '$colors$textSecondary',
  },
  '&:has(.story-assignment)': {
    $$assignmentBannerHeight: '64px',
  },
  // '&:has(.story-tab-bar)': {
  //   // extra 1px on each of these is for bottom border
  //   $$tabbarHeight: '81px',
  //   '@small': {
  //     $$tabbarHeight: '65px',
  //   },
  // },
});

const StoryDetailScreenStyles = (
  props: React.ComponentProps<typeof StoryDetailScreenStylesWrapper>
) => {
  return <StoryDetailScreenStylesWrapper id="story-detail-screen" {...props} />;
};

const MainContent = styled('div', {
  minHeight: 'calc(100vh - $$storyHeaderHeight)',
  background: 'white',
  position: 'relative',
  isolation: 'isolate',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
});

export const StoryDetailContent = observer(({ story }: { story: Story }) => {
  // track when it's safe to pop back from study view
  ReturnNavState.storyDetailVisited = true;

  useEnableUpdatePrompt();
  const navigate = useNavigate();

  /// PLACEHOLDER state to show or hide the vocab preview modal

  useStoryThumbScrollingFx();

  React.useEffect(() => {
    if (story) {
      story.ensureCacheState().catch(bugsnagNotify);
      storyDetailUiModel.setStory(story);
    }

    storyDetailUiModel.executeAutoscroll();

    return () => {
      storyDetailUiModel.reset();
    };
  }, [story]);

  const canGoBack = ReturnNavState.canPopFromStoryDetail();
  const assignment = story.joinedClassroomAssignment;
  const showTempDownloadControls = AppFactory.root.userManager.showUglyDevUI;

  return (
    <StoryDetailScreenStyles>
      <div
        className="story-header-container"
        id={elementIds.STORY_HEADER_CONTAINER}
      >
        <StoryHeader
          backAction={() =>
            canGoBack ? navigate(-1) : navigate(learnHomePath())
          }
          accessory={<StoryOverflowMenu story={story} />}
        />
      </div>
      <StoryDetailCard story={story} />
      <MainContent>
        <StoryDetailCardAssignment assignment={assignment} />

        {showTempDownloadControls ? (
          <Box css={{ padding: '$space$2' }}>
            <DownloadControlTemp story={story} />
          </Box>
        ) : null}

        <StoryLockedActions />
        <StoryTab />
        <StoryProgressPanel />
        <StorySubscribeCta />
        <EndOfStoryCtaDialog />
      </MainContent>
    </StoryDetailScreenStyles>
  );
});

import { isFunction } from 'lodash';
import dashjs, { MediaPlayerClass } from 'dashjs';
// import { breakpoints } from 'naan/tokens/breakpoints';
import { makeObservable, observable, action } from 'mobx';
import { createLogger } from '@common/log';
import { PlayerViewController } from './abstract';
import { TVideoStates, VideoStates } from './video-states';

const log = createLogger('DASH-player-controller');

export class DashPlayerViewController implements PlayerViewController {
  playerName = 'DASHPlayer';

  @observable
  state: TVideoStates = VideoStates.NOT_STARTED;

  videoElement: HTMLVideoElement | null = null;

  mediaPlayer: MediaPlayerClass | null = null;

  @observable
  canPlay = false;

  initialized: boolean = false;

  @observable
  mounted: boolean = true; // set to false by useEffect cleanup so we can kill the animation frame loop

  @observable
  autoplay: boolean = false;

  onEnded: () => void = () => {};
  onPlay: () => void = () => {};
  onPause: () => void = () => {};

  constructor() {
    this.initialized = false;
    makeObservable(this);
    this.mediaPlayer = dashjs.MediaPlayer().create();

    (window as any).vplayer = this;
  }

  initialize({
    videoElement,
    src,
    onEnded,
    onPlay,
    onPause,
  }: {
    src: string;
    videoElement: HTMLVideoElement;
    onEnded: () => void;
    onPlay?: () => void;
    onPause?: () => void;
  }) {
    this.mounted = true;
    if (this.initialized) {
      throw new Error('Cannot initialize player twice. Uninitialize first.');
    }

    this.state = VideoStates.NOT_STARTED;
    this.canPlay = false;

    this.videoElement = videoElement;

    this.mediaPlayer.initialize(
      videoElement,
      src,
      // autoplay
      this.autoplay
    );

    this.onEnded = onEnded;
    this.onPlay = onPlay;
    this.onPause = onPause;

    this.mediaPlayer.on('playbackEnded', this.handleEnded);
    this.mediaPlayer.on('playbackPaused', this.handlePause);
    this.mediaPlayer.on('playbackStarted', this.handlePlay);

    this.videoElement.addEventListener('canplay', this.handleCanPlay);
    this.videoElement.addEventListener('waiting', this.handleWaiting);

    this.videoElement.addEventListener('loadedmetadata', this.handleLoadedData);
    // this.videoElement.addEventListener(
    //   dashjs.MediaPlayer.events.STREAM_INITIALIZED,
    //   this.handleStreamInitialized
    // );
  }

  handlePlay = () => {
    log.info('PLAYING');
    this.setState(VideoStates.PLAYING);
    if (isFunction(this.onPlay)) {
      this.onPlay();
    }
  };

  handlePause = () => {
    log.info('PAUSED');
    // debugger;

    var currentTime = this.mediaPlayer.time();
    var duration = this.mediaPlayer.duration();
    // avoid triggering paused state when ended
    if (currentTime >= duration - 0.1) {
      log.info('ignoring PAUSED event at end of video');
      return;
    }

    // 'playbackPaused' seems to get called before 'playbackEnded' so this attempt didn't help
    // if (this.state !== VideoStates.ENDED) {

    this.setState(VideoStates.PAUSED);
    if (isFunction(this.onPause)) {
      this.onPause();
    }
  };

  handleEnded = () => {
    log.info('ENDED');
    // debugger;
    this.onEnded();
    this.setState(VideoStates.ENDED);
    this.mediaPlayer?.pause();
  };

  handleCanPlay = () => {
    this.setCanPlay(true);
  };

  handleWaiting = () => {
    this.setCanPlay(false);
  };

  handleLoadedData = () => {
    log.info('LOADED DATA');
  };

  // handleStreamInitialized = () => {
  //   log.info('STREAM INITIALIZED');
  //   const tracks = this.mediaPlayer.getTracksFor('text');
  //   if (tracks.length > 0) {
  //     // const captionsTrack = tracks[0];
  //     this.mediaPlayer.setTextTrack(0);
  //   }
  // };

  @action
  setState(state: TVideoStates) {
    this.state = state;
  }

  @action
  setCanPlay(canPlay: boolean) {
    this.canPlay = canPlay;
  }

  @action
  setAutoplay(autoplay: boolean) {
    this.autoplay = autoplay;
  }

  async goFullscreen() {
    if (this.videoElement && document.fullscreenElement !== this.videoElement) {
      await this.videoElement.requestFullscreen();
    }
  }

  async exitFullscreen() {
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    }
  }

  play = () => {
    this.mediaPlayer?.play();
  };

  async goToEnd() {
    log.info('GO TO END');
    /*await*/ this.mediaPlayer?.seek(this.mediaPlayer.duration());
    this.handleEnded();
    this.pause();
  }

  pause = () => {
    this.mediaPlayer?.pause();
  };

  get isNotStarted() {
    return this.state === VideoStates.NOT_STARTED;
  }

  get isPlaying() {
    return this.state === VideoStates.PLAYING;
  }

  get isPaused() {
    return this.state === VideoStates.PAUSED;
  }

  get isEnded() {
    return this.state === VideoStates.ENDED;
  }

  get isTransitioning() {
    return this.state === VideoStates.TRANSITIONING;
  }

  get willAutoPlay() {
    return this.autoplay;
  }

  @action
  startTransitioning() {
    this.setState(VideoStates.TRANSITIONING);
  }

  // called by useEffect cleanup
  reset() {
    this.mounted = false;
    if (this.initialized) {
      this.mediaPlayer.reset();
      this.videoElement.removeEventListener('canplay', this.handleCanPlay);
      this.videoElement.removeEventListener('waiting', this.handleWaiting);
      this.initialized = false;
      this.state = VideoStates.NOT_STARTED;
    }
  }
}

import { TVideoStates, VideoStates } from './video-states';
import Hls from 'hls.js';
import { createLogger } from '@common/log';
import { PlayerViewController } from './abstract';
import { action, makeObservable, observable } from 'mobx';
// import { displaySubtitles } from './utils';

const log = createLogger('HlsPlayerViewController');

export class HlsPlayerViewController implements PlayerViewController {
  playerName = 'HLSPlayer';

  @observable
  state: TVideoStates = VideoStates.NOT_STARTED;

  videoElement: HTMLVideoElement | null = null;

  @observable
  canPlay = false;

  initialized: boolean = false;

  @observable
  mounted: boolean = true; // set to true by useEffect cleanup so we can kill the animation frame loop

  @observable
  autoplay: boolean = false;

  onEnded: () => void = () => {};
  onPlay: () => void = () => {};
  onPause: () => void = () => {};

  constructor() {
    this.initialized = false;
    makeObservable(this);
    (window as any).vplayer = this;
  }

  initialize({
    videoElement,
    src,
    onEnded,
    onPlay,
    onPause,
  }: {
    src: string;
    videoElement: HTMLVideoElement;
    onEnded: () => void;
    onPlay?: () => void;
    onPause?: () => void;
  }): void {
    this.mounted = true;

    if (this.initialized) {
      throw new Error('Cannot initialize player twice. Uninitialize first.');
    }

    this.state = VideoStates.NOT_STARTED;
    this.canPlay = false;
    this.videoElement = videoElement;

    if (!this.videoElement) {
      throw new Error('Video element is required');
    }

    // Handle HLS source loading
    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(src);
      hls.attachMedia(this.videoElement);

      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        log.info('HLS Manifest parsed, HLS initialized');
        this.setCanPlay(true);
        // if (this.autoplay) {
        //   this.play();
        // }
      });

      // // chatgpt derived code
      // hls.on(Hls.Events.SUBTITLE_TRACKS_UPDATED, function (event, data) {
      //   // hls.subtitleTracks.forEach((track, index) => {
      //   //   log.debug(`Subtitle track ${index} - ${track.name}`);
      //   // });
      //   if (hls.subtitleTracks?.length) {
      //     hls.subtitleTrack = 0; // Enable the first subtitle track
      //   }
      // });

      hls.on(Hls.Events.ERROR, (event, data) => {
        log.error('HLS Error', data);
      });
    } else if (this.videoElement.canPlayType('application/vnd.apple.mpegurl')) {
      // Native HLS support (Safari)
      this.videoElement.src = src;
    } else {
      log.error('HLS is not supported in this environment.');
    }

    this.onEnded = onEnded;
    this.onPlay = onPlay || (() => {});
    this.onPause = onPause || (() => {});

    // Set up event listeners
    this.videoElement.addEventListener('play', this.handlePlay);
    this.videoElement.addEventListener('pause', this.handlePause);
    this.videoElement.addEventListener('ended', this.handleEnded);
    this.videoElement.addEventListener('canplay', this.handleCanPlay);
    this.videoElement.addEventListener('waiting', this.handleWaiting);
    this.videoElement.addEventListener('loadedmetadata', this.handleLoadedData);
    this.videoElement.addEventListener('error', e => {
      log.error('VIDEO ERROR', e);
    });

    this.initialized = true;
  }

  handlePlay = () => {
    log.info('PLAYING');
    this.setState(VideoStates.PLAYING);
    if (this.onPlay) {
      this.onPlay();
    }
  };

  handlePause = () => {
    log.info('PAUSED');

    var currentTime = this.videoElement.currentTime;
    var duration = this.videoElement.duration;
    // avoid triggering paused state when ended
    if (currentTime >= duration - 0.1) {
      log.info('ignoring PAUSED event at end of video');
      return;
    }

    this.setState(VideoStates.PAUSED);
    if (this.onPause) {
      this.onPause();
    }
  };

  handleEnded = () => {
    log.info('ENDED');
    this.setState(VideoStates.ENDED);
    this.onEnded();
    if (this.videoElement) {
      this.videoElement.pause();
    }
  };

  handleCanPlay = () => {
    log.info('CAN PLAY');
    this.setCanPlay(true);
  };

  handleWaiting = () => {
    log.info('WAITING');
    this.setCanPlay(false);
  };

  handleLoadedData = () => {
    log.info('LOADED DATA');
    // displaySubtitles(this.videoElement!, this.subtitlesElement!);
  };

  setAutoplay(autoplay: boolean): void {
    this.autoplay = autoplay;
  }

  @action
  setState(state: TVideoStates): void {
    this.state = state;
  }

  setCanPlay(canPlay: boolean): void {
    this.canPlay = canPlay;
  }

  play(): void {
    if (this.videoElement) {
      this.videoElement.play().catch(error => {
        throw error;
      });
    }
  }

  pause(): void {
    this.videoElement?.pause();
  }

  goToEnd(): void {
    if (this.videoElement) {
      log.info('GO TO END');
      this.videoElement.currentTime = this.videoElement.duration;
      this.handleEnded();
      this.pause();
    }
  }

  get isNotStarted(): boolean {
    return this.state === VideoStates.NOT_STARTED;
  }

  get isPlaying(): boolean {
    return this.state === VideoStates.PLAYING;
  }

  get isPaused(): boolean {
    return this.state === VideoStates.PAUSED;
  }

  get isEnded(): boolean {
    return this.state === VideoStates.ENDED;
  }

  get isTransitioning(): boolean {
    return this.state === VideoStates.TRANSITIONING;
  }

  get willAutoPlay(): boolean {
    return false;
  }

  startTransitioning(): void {
    this.setState(VideoStates.TRANSITIONING);
  }

  // called by useEffect cleanup
  reset(): void {
    this.mounted = false;
    if (this.initialized) {
      if (this.videoElement) {
        this.videoElement.removeEventListener('play', this.handlePlay);
        this.videoElement.removeEventListener('pause', this.handlePause);
        this.videoElement.removeEventListener('ended', this.handleEnded);
        this.videoElement.removeEventListener('canplay', this.handleCanPlay);
        this.videoElement.removeEventListener('waiting', this.handleWaiting);
        this.videoElement.src = ''; // Clear video source
      }
      this.videoElement = null;
      this.initialized = false;
      this.setState(VideoStates.NOT_STARTED);
    }
  }
}

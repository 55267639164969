import { createLogger } from 'app/logger';
const log = createLogger('preload-image');

export function preloadImage(url: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve();
    img.onerror = () => reject(new Error(`Failed to SVG image: ${url}`));
  });
}

export function preloadImages(urls: string[]): Promise<void> {
  // Preload all SVG images concurrently
  return Promise.all(urls.map(preloadImage)).then(() => {
    log.info('All SVG images preloaded successfully.');
  });
}

import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { observer } from 'mobx-react';
import { DialogHtmlContainer } from 'components/ui/dialog-html-container';

import __ from '@core/lib/localization';

export const ResetStoryDialog = observer(
  ({ onReset, onDismiss }: { onReset: () => void; onDismiss: () => void }) => {
    return (
      <DialogHtmlContainer onDismiss={onDismiss}>
        <Dialog.HtmlHeading>
          {__('Reset story', 'resetStory')}
        </Dialog.HtmlHeading>
        <Dialog.Body>
          {__(
            // "This will mark all chapters as unlistened and will remove any words in your vocab list for this story, but won't affect your progress statistics.",
            // 'thisWillMarkAllChaptersAsUnlistened'
            "This will mark all chapters as unlistened, but won't affect your completed Soundbites, saved vocabulary or progress statistics.",
            'thisWillMarkAllChaptersAsUnlistenedButNotSoundbitesEtc'
          )}
        </Dialog.Body>
        <Dialog.ButtonsContainer>
          <Dialog.Button
            onClick={onDismiss}
            label={__('Cancel', 'cancel')}
            presentation="grayTransparent"
          />
          <Dialog.Button
            onClick={onReset}
            label={__('Reset', 'reset')}
            presentation="teal"
          />
        </Dialog.ButtonsContainer>
      </DialogHtmlContainer>
    );
  }
);

import * as React from 'react';
import { getSourceForId } from 'components/ui/video-player/player-view-controller';
import { VideoPlayer2 } from 'components/ui/video-player/video-player2/player';

// import subtitles from './video-assets/subtitles.vtt?url';

export const pathname = '/video';
export const navlabel = 'Video';
export const naanReady = true;

const videoId = '556a13523d25a08dd955f3d9274a6a93';

export const Screen = () => {
  const src = getSourceForId(videoId);
  return (
    <VideoPlayer2
      title="demo"
      poster={'https://via.placeholder.com/1080x1920'}
      videoSrc={src}
      // subtitlesUrl={subtitles}
      // eslint-disable-next-line no-console
      onComplete={() => console.log('video completed')}
      // eslint-disable-next-line no-console
      onExit={() => console.log('video exited')}
      onEnded={() => {
        // eslint-disable-next-line no-console
        console.log('video ended');
      }}
    />
  );
};

import bing from 'audio-assets/bing.mp3?url';
import swoosh from 'audio-assets/swoosh.mp3?url';
import { createLogger } from '@common/log';
import { bugsnagNotify } from './notification-service';

const log = createLogger('soundmanager');

const sounds = {
  bing,
  swoosh,
} as const;

type SoundKey = keyof typeof sounds;

export class SoundManager {
  private static __instance: SoundManager;
  private sounds: Map<string, string> = new Map(); // Map to store sound sources

  private constructor() {
    // this.init();
  }

  // private init() {
  //   const keys = Object.keys(sounds) as SoundKey[];
  //   keys.forEach(key => {
  //     this.loadSound(key, sounds[key]);
  //   });
  // }

  public static get instance(): SoundManager {
    if (!SoundManager.__instance) {
      SoundManager.__instance = new SoundManager();
    }
    return SoundManager.__instance;
  }

  // public loadSound(key: SoundKey, src: string): void {
  //   if (this.sounds.has(key)) {
  //     return;
  //   }

  //   this.sounds.set(key, src);
  // }

  public prefetchSound(key: SoundKey): void {
    if (this.sounds.has(key)) {
      return;
    }

    const src = sounds[key];
    if (src) {
      const audio = new Audio(src);
      audio.load();
      audio.addEventListener('loadeddata', () => {
        log.info(`Sound [${key}] prefetched`);
        this.sounds.set(key, src);
      });
      audio.addEventListener('error', event => {
        bugsnagNotify(
          `Error loading sound [${key}] code: ${event.error.code}, message: ${event.error.message}`
        );
      });
    }
  }

  public playSound(key: SoundKey): void {
    const src = this.sounds.get(key);
    if (src) {
      /// this cloned audio trick is necessary to play the same sound multiple times
      /// otherwise, no sound can be played unless the previous one is finished
      const audio = new Audio(src);
      audio.addEventListener('error', event => {
        bugsnagNotify(
          `Error loading sound [${key}] code: ${event.error.code}, message: ${event.error.message}`
        );
      });
      void audio.play();
    } else {
      bugsnagNotify(`Sound key [${key}] not found`);
    }
  }

  // public stopSound(key: string): void {
  //   // stopping a specific sound is tricky when using cloned instances.
  // }
}

export function playSound(key: SoundKey): void {
  SoundManager.instance.playSound(key);
}

export function prefetchSounds(keys: SoundKey[]): void {
  keys.forEach(key => {
    SoundManager.instance.prefetchSound(key);
  });
}

(window as any).SoundManager = SoundManager.instance;

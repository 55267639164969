import { appConfig } from '@app/env';
// import { createLogger } from '@common/log';

export function getPosterUrl(poster: string) {
  return `${appConfig.videoSupportAssetBaseUrl}/${poster}`;
}

export function getVideoSupportAssetUrl(tail: string) {
  return `${appConfig.videoSupportAssetBaseUrl}/${tail}`;
}

// experimentally using just the standard caption support
// export function displaySubtitles(
//   videoElement: HTMLVideoElement,
//   subtitlesElement: HTMLDivElement
// ) {
//   const log = createLogger('displaySubtitles');
//   log.info('Will initialize subtitles handling');

//   const track = videoElement?.textTracks[0];
//   if (!track) {
//     log.error('No track element found');
//     return;
//   }

//   if (!subtitlesElement) {
//     log.error('No subtitles element provided');
//     return;
//   }
//   // Set the track mode to hidden so it doesn't display natively
//   track.mode = 'hidden';

//   // Listen for changes in the active cues
//   track.addEventListener('cuechange', () => {
//     log.info('CUE CHANGE EVENT');
//     const activeCues = track.activeCues;
//     if (activeCues.length > 0) {
//       const cue = activeCues[0] as VTTCue;
//       const text = cue.text;
//       log.info('CUE CHANGE', text);

//       // Update the subtitles element with the cue text
//       subtitlesElement.innerText = text;
//       subtitlesElement.classList.add('show'); // Add any class for styling if needed
//     } else {
//       // Hide subtitles when no cues are active
//       subtitlesElement.innerText = '';
//       subtitlesElement.classList.remove('show');
//     }
//   });
// }

export const getSourceForId = (videoId: string) => {
  if (supportsMediaSource()) {
    return getCloudflareDashUrlFromId(videoId);
  } else {
    return getCloudflareHslUrlFromId(videoId);
  }
};

const getCloudflareDashUrlFromId = (videoId: string) => {
  return `https://customer-4gz6yh9017ekbrye.cloudflarestream.com/${videoId}/manifest/video.mpd`;
};

const getCloudflareHslUrlFromId = (videoId: string) => {
  return `https://customer-4gz6yh9017ekbrye.cloudflarestream.com/${videoId}/manifest/video.m3u8`;
};

function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function supportsMediaSource() {
  if (isSafari()) {
    return false;
  }
  return 'MediaSource' in window || 'WebKitMediaSource' in window;
}

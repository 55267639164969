import React from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from '@naan/primitives/button';
import { styled } from '@naan/stitches.config';
import { AppFactory } from '@app/app-factory';
import { PlayTriangleIcon } from '@naan/icons/play-triangle-icon';
import miedoBg from './assets/miedo-bg.png';
import aVeryLuckWindBg from './assets/a-very-lucky-wind-bg.png';
import { LocaleCode } from '@utils/util-types';

import __ from '@core/lib/localization';

const bgImages: Partial<Record<LocaleCode, string>> = {
  en: miedoBg,
  pt: aVeryLuckWindBg,
  es: aVeryLuckWindBg,
} as const;

const FreeStoryWidgetWrapper = styled('div', {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  overflow: 'hidden',
  height: 280,

  '& .banner': {
    display: 'inline-block',
    background: '$colors$yellow-300',
    color: '$colors$orange-700',
    textStyle: 'small-text-bold',
    textTransform: 'uppercase',
    padding: '40px 32px 4px',
    position: 'absolute',
    top: 0,
    left: 0,
    '&:before': {
      content: '""',
      display: 'block',
      width: '100%',
      paddingTop: '100%',
    },
    transform: 'translate(-50%, -50%) rotate(-45deg)',
    '@extraLarge': {
      textStyle: 'small-heading',
      padding: '56px 56px 4px',
    },
  },

  '& .body': {
    margin: '0 auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    gap: 16,
    padding: '24px 16px',

    '& .text': {
      color: '$colors$white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      gap: 4,
      maxWidth: 210,
      '& .title': {
        textStyle: 'medium-heading',
      },
      '& .copy': {
        textStyle: 'small-text',
      },
    },
  },

  '@medium': {
    height: 336,
    '& .body': {
      padding: '0 24px 8px',
      maxWidth: 1008, // 960 + 24 + 24
      justifyContent: 'center',
      '& .text': {
        '& .title': {
          textStyle: 'large-heading',
        },
        '& .copy': {
          textStyle: 'body',
        },
      },
    },
  },
});

export const FreeStoryWidget = observer(() => {
  const l1 = AppFactory.root.l1;
  const navigate = useNavigate();
  const { storyManager } = AppFactory.root;
  const story = storyManager.onboardingStory;
  const bgImage = bgImages[l1];

  const label = story.inProgress
    ? __('Continue', 'continue')
    : __('Begin free story', 'beginFreeStory');
  // const showFreeStoryWidget = storyManager.onboardingStory?.unstarted;

  // we now assume display check is made at higher level
  // if (showFreeStoryWidget) {
  return (
    <FreeStoryWidgetWrapper
      className="free-story-widget"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="banner">{__('Free', 'free')}</div>

      <div className="body">
        <div className="text">
          <h2 className="title">{story.title}</h2>
          <div className="copy">{story.tagline}</div>
        </div>
        <Button
          label={label}
          size="large"
          presentation="teal"
          leftIcon={<PlayTriangleIcon />}
          alignment="unset"
          onClick={() => {
            navigate(storyManager.onboardingStoryPath);
          }}
        />
      </div>
    </FreeStoryWidgetWrapper>
  );
  // } else {
  //   return null;
  // }
});

import { pickLocalizedValue } from '@core/lib/localization';
import { AppFactory } from '@app/app-factory';

const poster = 'recap-poster.svg';
// for now we have only the one video asset
const videoId = '0935d8ca1e64c63028908e87b6da227b';
const shortVideoId = '3960a9b4b190c2e8ec144c03eff186ab';

const en = {
  videoId,
  poster,
};

const pt = {
  videoId,
  poster,
};

const es = {
  videoId,
  poster,
};

export function getVideoAssets() {
  const result = pickLocalizedValue({ en, pt, es });
  const { shortVideosEnabled } = AppFactory.root.localState;
  if (shortVideosEnabled) {
    result.videoId = shortVideoId;
  }
  return result;
}

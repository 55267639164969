import { AppFactory } from './../../../app/app-factory';
import { openUrl } from '@naan/primitives/text/external-link';
import { marketingSite } from './../../../components/nav/path-helpers';
import { embeddedMode } from './../../lib/app-util';
import { compact, isEmpty, pick } from 'lodash';

import { createLogger } from 'app/logger';
import {
  applySnapshot,
  frozen,
  ModelTreeNode,
  snap,
  TSTStringMap,
} from 'ts-state-tree/tst-core';

import {
  END_OF_STORY_CHAPTER,
  END_OF_VOLUME_UNIT,
} from 'core/lib/constants/vars';

import { ChapterRef, LocationPointer } from './location-pointer';
import { Story } from '../story-manager';
import { Root } from '../root';
import { /*IObservableArray,*/ runInAction } from 'mobx';
import { ListeningStats } from './listening-stats';
import { getBaseRoot } from '../app-root';
import { ChapterCatalogData, UnitCatalogData } from '../catalog';
import { ListeningLog } from './listening-log';
import {
  hasBogotaVocabSlugs /*, isBogotaVocabSlug*/,
} from '../story-manager/story';
import { bugsnagNotify } from '@app/notification-service';
import { notEmpty } from '@utils/conditionals';
import {
  isDirtyMasalaElementId,
  // migrateBogotaVocabSlugs,
  // sanitizeDirtyMasalaElementId,
} from '../catalog/unit-catalog-data';
import { track } from '@app/track';
import { ClientNotation } from '@tikka/client/client-types';
import { clearMapWithFalseValues } from '@utils/util';
import { OnboardingService } from '@app/onboarding/onboarding-service';
import { PlayerMode } from '@common/misc-types';
import { PlayerSessionData } from 'player/models/base-player-model';
import { compressToEncodedURIComponent } from 'lz-string';
import { VOCAB_REVIEW_ITEM_POINTS } from './activity-log';
import { SentenceId } from '@tikka/basic-types';
import { achieve } from '@app/onboarding/achievements';

const log = createLogger('um:story-progress');

function createTSVDownload(tsvText: string, slug: string) {
  const blob = new Blob([tsvText], { type: 'text/tsv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = slug + '.tsv';
  a.click();
  URL.revokeObjectURL(url);
}

function openStaticDownloadLink(tsvText: string, slug: string) {
  const l1 = AppFactory.root.l1;
  const encodedData = encodeURIComponent(
    compressToEncodedURIComponent(tsvText)
  );
  const encodedFileName = encodeURIComponent(slug + '.tsv');

  // create url from string
  const url =
    marketingSite() +
    `/${l1}/vocab-export/download?filename=${encodedFileName}&data=${encodedData}`;
  openUrl(url);

  // const blob = new Blob([tsvText], { type: 'text/tsv' });
  // const url = URL.createObjectURL(blob);
  // const a = document.createElement
}

// the manually managed story states which are exclusive from each other
export const enum StoryState {
  // eslint-disable-next-line no-unused-vars
  UNQUEUED = 'UNQUEUED',
  // eslint-disable-next-line no-unused-vars
  QUEUED = 'QUEUED',
  // eslint-disable-next-line no-unused-vars
  STARTED = 'STARTED', // assigned when 'Begin studying' performed; spans both "in progress" and "completed"
  // eslint-disable-next-line no-unused-vars
  DELETED = 'DELETED', // hack state to make sure borked progress data isn't resurrected
}

export type ChapterStage = 'study' | 'soundbites';
export type ChapterStudyMode = 'study' | 'listen' | 'completed';

// represents where a previous vocab review session was exited
export type VocabReviewPointer = ChapterRef & {
  sentenceId: SentenceId;
  sentenceAddress: number;
};

/**
 * StoryProgress
 *
 * holds user's listening progress of a particular story
 */
export class StoryProgress extends ModelTreeNode {
  static CLASS_NAME = 'StoryProgress' as const;

  static create(snapshot: any) {
    return super.create(StoryProgress, snapshot) as StoryProgress;
  }

  slug: string;
  storyState: StoryState; // the manually managed story states which are exclusive from each other
  currentPoint: LocationPointer = snap({});
  furthestPoint: LocationPointer = snap({});

  vocabMap: TSTStringMap<boolean> = snap({}); // conceptually a set, keys are notation id's. value set to false when removed
  learnedVocab: TSTStringMap<boolean> = snap({}); // similar set representing vocabs marked as 'learned'

  // not a tst instance, just a typed pojo
  @frozen
  vocabReviewPointer: VocabReviewPointer;

  vocabs?: string[]; // deprecated, legacy schema; word group slugs, i.e. "765-cruzar" (millis/100 + first word)
  orphanedVocabs: string[] = []; // archive the vocab slugs we failed to match
  // vocabs to be migrated when the user first visits the story detail page.
  // populated during initial migration of bogota user data.
  // map of unit slug to list of unit scoped legacy vocab slugs
  pendingBogotaVocabs: TSTStringMap<string[]> = snap({});

  // @jason, this is a correct usage of @frozen? - it seemed to behave bizarrely and bled
  // data between parent objects
  // @frozen
  // pendingBogotaVocabs: { [index: string]: string[] } = {};

  lastListened: number = 0; // millis since epoch

  // counter used to prevent duplicate logging of session listening stats
  // at the start of a session, the next counter is fetched
  // when recording progress the fetched counter will be compared to this
  // if greater, then record and update the persisted counter
  // if less or equal, then ignore
  lastRecordedSessionCounter: number = 0;

  get root(): Root {
    return getBaseRoot(this);
  }

  nonBlockingPersist() {
    // log.debug('nonBlockingPersist');
    this.root.userManager.persistUserData().catch(bugsnagNotify);
  }

  get story(): Story {
    const { storyManager } = this.root;
    if (!storyManager) return null;
    return storyManager.story(this.slug);
  }

  get currentUnit(): UnitCatalogData {
    // if (this.currentPoint.atEndOfStory) {
    if (this.completed) {
      return null;
    }
    const unitNumber = this.currentPoint.unit;
    return this.story?.unitDataByNumber(unitNumber);
  }

  get currentChapter(): ChapterCatalogData {
    if (this.storyState === StoryState.STARTED) {
      return this.story?.chapterForPoint(this.currentPoint);
    } else {
      return null;
    }
  }

  get currentChapterPosition(): number {
    return this.currentChapter?.position;
  }

  get currentStage(): ChapterStage {
    if (this.currentPoint?.chapterPlayed) {
      return 'study';
    } else {
      if (this.firstIncompleteSoundbiteIndex >= 0) {
        return 'soundbites';
      } else {
        return 'study';
      }
    }
  }

  get firstIncompleteSoundbiteIndex(): number {
    return this.currentChapter?.firstIncompleteSoundbiteIndex ?? -1;
  }

  get currentStudyMode(): ChapterStudyMode {
    return this.currentPoint?.studyMode;
  }

  get furthestChapter(): ChapterCatalogData {
    return this.story?.chapterForPoint(this.furthestPoint);
  }

  // when current chapter is before furthest chapter
  get inReviewMode(): boolean {
    return !this.furthestPoint.matchesChapter(this.currentPoint);
  }

  get notEmpty(): boolean {
    return (
      (this.storyState && this.storyState !== StoryState.UNQUEUED) ||
      this.currentPoint?.played ||
      this.furthestPoint?.played ||
      (this.vocabMap.size || 0) > 0 ||
      (this.vocabs?.length || 0) > 0 ||
      this.lastListened > 0
    );
  }

  get isEmpty(): boolean {
    return !this.notEmpty;
  }

  get hasVolumeSlug(): boolean {
    const { storyManager } = this.root;
    return !!storyManager.story(this.slug);
  }

  // probably not useful
  get hasUnitSlug(): boolean {
    const { storyManager } = this.root;
    return !!storyManager.storyForUnitSlug(this.slug);
  }

  // progress record with a unit slug not matching a story slug.
  // legacy data which needs merging into story level progress record
  get needsMerge(): boolean {
    const { storyManager } = this.root;
    const story = storyManager.storyForUnitSlug(this.slug);
    return story && story.slug !== this.slug;
  }

  get needsVocabMigration(): boolean {
    return hasBogotaVocabSlugs(this.vocabs); // || hasBogotaVocabSlugs(this.vocabList);
  }

  get hasBogotaVocabSlugs(): boolean {
    return (
      hasBogotaVocabSlugs(this.vocabs) || hasBogotaVocabSlugs(this.vocabList)
    );
  }

  get valid(): boolean {
    return this.hasVolumeSlug; // || this.hasUnitSlug;
  }

  get orphaned(): boolean {
    return !this.valid && !this.hasBorkedMigrationData;
  }

  updateCurrentPoint(point: LocationPointer, updateLastListened = false) {
    log.info(
      `updateCurrentPoint - new point: ${point.sortableString}, old furthest: ${this.furthestPoint.sortableString}}`
    );
    runInAction(() => {
      applySnapshot(this.currentPoint, point);
      if (this.furthestPoint.isLessThan(point)) {
        log.info(`new furthest point: ${point.sortableString}`);
        applySnapshot(this.furthestPoint, point);
      }
      if (updateLastListened) {
        this.lastListened = Date.now(); // millis since epoch
      }
    });
  }

  // fetched at start of session
  get nextSessionCounter(): number {
    return (this.lastRecordedSessionCounter || 0) + 1;
  }

  // confirms that we have the expected session counter to record
  isNextSessionCounter(counter: number) {
    return counter === this.nextSessionCounter;
  }

  // invoked when session recorded
  incrementNextSessionCounter() {
    this.lastRecordedSessionCounter = this.nextSessionCounter;
  }

  // record study progress and listening stats
  recordSession({
    chapterRef,
    sessionCounter,
    completionReached,
    furthestMillis,
    millisPlayed,
    startingMode,
  }: PlayerSessionData) {
    const secondsPlayed = Math.round(millisPlayed / 1000);
    log.debug(
      `recordSession chap: ${chapterRef.unit}-${chapterRef.chapter}, sessionCounter: ${sessionCounter}, completed: ${completionReached}, furthestMillis: ${furthestMillis}; lrsc: ${this.lastRecordedSessionCounter}, secondsPlayed: ${secondsPlayed}`
    );
    if (secondsPlayed < 1) {
      log.info('not played - ignoring');
      return;
    }

    const { userManager } = this.root;

    // log.info(`old cp: ${this.currentPoint.sortableString}`);
    if (!this.isNextSessionCounter(sessionCounter)) {
      log.error(`recordSession w/ mismatched sessionCounter - mostly ignoring`);
      this.nonBlockingPersist(); // make sure latest vocab and settings are saved out
      return;
    }

    const date = this.root.storyManager.currentDate;
    track('story__record_session', {
      storySlug: this.slug,
      // l2 - automatic
      // userId - automatic
      partner: this.story?.partnerId,
      kind: 'listen',
      subKind: 'story',
      isTrial: this.story?.trial,
      unit: chapterRef?.unit,
      chapter: chapterRef?.chapter,
      sessionCounter,
      completionReached,
      furthestMillis,
      date, // used by engagement logs
      millisPlayed,
      secondsPlayed,
      startingMode,
    });

    this.incrementNextSessionCounter(); // prevents double counting session

    if (this.storyState !== StoryState.STARTED) {
      log.warn(`unexpected storyState: ${this.storyState}`);
      this.setStoryState(StoryState.STARTED);
    }

    log.trace(
      `current chapter: ${this.currentChapter?.unitNumber}/${this.currentChapter?.position}`
    );
    // can currently happen when listening after story completed
    log.warn('updateProgress - no currentChapter');

    // JRW: @jfe should we put clone() on ModelTreeNode or maybe make a function which takes a ModelTreeNode?
    const newPoint = LocationPointer.create(this.currentPoint.snapshot);
    newPoint.lastMode = startingMode;

    if (!this.currentChapter || !this.currentChapter.matchesPoint(chapterRef)) {
      // can happen when deep linking to a particular chapter
      log.warn('progress unexpectedly recorded against non-current chapter');
      // do our best to repair the unexpected state
      const presumedIteration =
        this.currentChapter && this.currentChapter.isBefore(chapterRef) ? 1 : 3;
      newPoint.setChapterRef(chapterRef);
      newPoint.setIteration(presumedIteration);
    }

    let automaticallyMarkComplete = false;

    if (secondsPlayed > 0) {
      // now tracks partial chapter listening stats
      userManager.userData.addListeningLog({
        storySlug: this.slug,
        millis: millisPlayed, // reflects sentence level coverage
      });
    }

    if (completionReached) {
      track('player__chapter_completed', {
        storySlug: this.slug,
        unit: chapterRef?.unit,
        chapter: chapterRef?.chapter,
        iteration: newPoint.iteration, // confirm this value
        startingMode,
      });
      userManager.userData.trackWeeklyEngagement(); // should probably ditch this, it's not very meaningful

      newPoint.incrementIteration();
      if (startingMode === PlayerMode.FLUENT_LISTEN) {
        newPoint.listenComplete = true;
      } else {
        newPoint.studyComplete = true;
      }

      // only automatically advance when in review mode. otherwise, explicit user action needed
      if (newPoint.completedIteration && this.inReviewMode) {
        automaticallyMarkComplete = true;
      }
    } else {
      newPoint.setMillisPlayed(furthestMillis);
    }

    this.updateCurrentPoint(newPoint, true /* updateLastListened */);

    // could be optimized into a single operation
    if (automaticallyMarkComplete) {
      log.debug('automatically marking complete');
      this.markCurrentChapterComplete(); // persists
    } else {
      this.nonBlockingPersist();
    }
  }

  // count sentence level playing time toward listening/audit stats
  recordSoundbiteSession(sessionData: PlayerSessionData) {
    log.debug(`recordSoundbiteSession: ${JSON.stringify(sessionData)}`);
    const { userManager } = this.root;

    const secondsPlayed = Math.round(sessionData.millisPlayed / 1000);
    if (secondsPlayed > 0) {
      userManager.userData.addListeningLog({
        storySlug: this.slug,
        millis: sessionData.millisPlayed,
      });
    }

    // this event drives the engagement log data recorded into mongodb
    const date = this.root.storyManager.currentDate;
    track('soundbite__record_session', {
      storySlug: this.slug,
      partner: this.story?.partnerId,
      kind: 'listen',
      subKind: 'soundbite',
      isTrial: this.story?.trial,
      date,
      secondsPlayed,
    });

    this.nonBlockingPersist();
  }

  recordVocabReviewSession(count: number) {
    log.debug(`recordVocabReviewSession: ${count}`);
    const { userManager } = this.root;
    userManager.userData.addActivityLog({
      storySlug: this.slug,
      kind: 'VOCAB_REVIEW',
      count,
      points: count * VOCAB_REVIEW_ITEM_POINTS,
    });
    this.nonBlockingPersist();
  }

  // record the current position to allow resuming later
  saveVocabReviewPointer(pointer: VocabReviewPointer) {
    log.debug(`saveVocabReviewPointer: ${JSON.stringify(pointer)}`);
    this.vocabReviewPointer = pointer;
    this.nonBlockingPersist();
  }

  clearVocabReviewPointer() {
    log.debug('clearVocabReviewPointer');
    this.vocabReviewPointer = null;
    this.nonBlockingPersist();
  }

  get vocabList(): string[] {
    // return Array.from(this.vocabMap.keys());
    const result: string[] = [];
    this.vocabMap.forEach((value, key) => {
      if (!!value) {
        result.push(key);
      }
    });
    return result;
  }

  vocabExists(slug: string): boolean {
    // return this.vocabMap.has(slug);
    return !!this.vocabMap.get(slug);
  }

  vocabLearned(slug: string): boolean {
    return !!this.learnedVocab.get(slug);
  }

  get learnedVocabList(): string[] {
    const result: string[] = [];
    // filter out the 'false' valued map entries
    this.learnedVocab.forEach((value, key) => {
      if (!!value) {
        result.push(key);
      }
    });
    return result;
  }

  addVocab(slug: string, { persist }: { persist: boolean }): void {
    if (!this.vocabExists(slug)) {
      this.vocabMap.set(slug, true);
      OnboardingService.instance.onVocabAdded();
    }
    if (persist) {
      this.nonBlockingPersist();
      // only report one-off adds, not migration
      track('story__add_vocab', { storySlug: this.slug, vocabSlug: slug });
    }
  }

  addVocabs(slugs: string[] = [], { persist }: { persist: boolean }): void {
    log.info(`addVocabs[${slugs}]`);
    for (const slug of slugs) {
      this.addVocab(slug, { persist: false });
    }
    if (persist) {
      this.nonBlockingPersist();
    }
  }

  removeVocab(slug: string, { persist }: { persist: boolean }): void {
    log.info(`removeVocab(${slug})`);
    // this.vocabMap.delete(slug);

    // hard to sync removals, so using 'false' values moving forward to represent deletions
    this.vocabMap.set(slug, false);
    // we now need to handle removing either to-review or already-learned vocabs when closing the list manager
    this.learnedVocab.set(slug, false);
    if (persist) {
      this.nonBlockingPersist();
      // don't track dirty slug cleanup
      track('story__remove_vocab', { storySlug: this.slug, vocabSlug: slug });
    }
  }

  clearVocabs(): void {
    log.info('clearVocabs');
    // (this.vocabs as IObservableArray).clear();
    // applySnapshot(this.vocabMap, {});
    // this.vocabMap.clear();
    clearMapWithFalseValues(this.vocabMap);
    clearMapWithFalseValues(this.learnedVocab);
  }

  removeVocabs(slugs: string[] = [], { persist }: { persist: boolean }) {
    log.info(`removeVocabs[${slugs}]`);
    runInAction(() => {
      for (const slug of slugs) {
        this.removeVocab(slug, { persist: false });
      }
    });
    if (persist) {
      this.nonBlockingPersist();
    }
  }

  unlearnVocabs(slugs: string[] = [], { persist }: { persist: boolean }) {
    log.info(`unlearnVocabs[${slugs}]`);
    runInAction(() => {
      for (const slug of slugs) {
        this.vocabMap.set(slug, true);
        this.learnedVocab.set(slug, false);
      }
    });
    if (persist) {
      this.nonBlockingPersist();
    }
  }

  markVocabLearned(slug: string, { persist }: { persist: boolean }): void {
    log.info(`markVocabLearned(${slug})`);
    this.vocabMap.set(slug, false);
    this.learnedVocab.set(slug, true);
    if (persist) {
      this.nonBlockingPersist();
      track('story__mark_vocab_learned', {
        storySlug: this.slug,
        vocabSlug: slug,
      });
    }
  }

  /**
   * remove any vocabs now found in current story data
   */
  pruneOrphanVocabs(): void {
    const { story } = this;
    if (!story) {
      bugsnagNotify(
        `pruneOrphanVocabs - unable to resolve story for slug: ${this.slug}`
      );
      return;
    }
    const vocabLookupData = story.vocabLookupData || [];
    const orphans = this.vocabList.filter(slug => {
      return !(vocabLookupData as any)[slug]; // TODO
    });
    if (orphans.length > 0) {
      log.info(`removing orphaned vocabs: ${JSON.stringify(orphans)}`);
      this.removeVocabs(orphans, { persist: false });
    }
  }

  get showVocabExportUi(): boolean {
    return (
      this.vocabCount > 0 &&
      this.root?.userManager?.userData?.userSettings?.showVocabListExportOption
    );
  }

  async exportVocab(): Promise<boolean> {
    const { story } = this;
    if (!story) {
      bugsnagNotify(
        `exportVocab - unable to resolve story for slug: ${this.slug}`
      );
      return false;
    }
    const volumeData = await story.loadVolumeData();
    const vocabSlugs = [...this.vocabList, ...this.learnedVocabList];
    const vocabData: string[][] = [];

    // derived from old rails logic, not sure what's still relelvant
    const clean = (text: string) => text?.replace(/[_*+]/g, '');

    for (const slug of vocabSlugs) {
      const notation = volumeData.vocab(slug);
      if (notation) {
        const usageText = clean(vocabUsageText(notation));
        const note = clean(notation.note);
        vocabData.push([usageText, note]);
      } else {
        log.error(`vocab slug not resolved: ${slug}`);
      }
    }

    if (notEmpty(vocabData)) {
      const lines = vocabData.map(row => row.join('\t'));
      const tsvData = lines.join('\r\n');
      if (embeddedMode()) {
        openStaticDownloadLink(tsvData, this.slug);
      } else {
        createTSVDownload(tsvData, this.slug);
      }

      track('story__export_vocab', {
        storySlug: this.slug,
        count: vocabData.length,
        mode: 'spa-download',
      });

      return true;
    } else {
      track('story__export_vocab', {
        storySlug: this.slug,
        noData: true,
        count: 0,
      });
      return false;
    }
  }

  markStoryComplete() {
    const { userManager } = this.root;
    track('story__mark_complete', { storySlug: this.slug });

    const location = {
      unit: END_OF_VOLUME_UNIT,
      chapter: END_OF_STORY_CHAPTER,
    };
    this.storyState = StoryState.STARTED;
    this.currentPoint = LocationPointer.create(location);
    this.furthestPoint = LocationPointer.create(location);
    // this.setStoryStatus(StoryStatus.COMPLETED);
    userManager.persistUserData().catch(bugsnagNotify); // async
    this.story?.ensureCacheState()?.catch(bugsnagNotify); // async

    const { hasDoneVocabReview } = userManager.userData;
    // const vocabCount = this.vocabList.length;

    if (!hasDoneVocabReview) {
      const added = this.autoPopulateVocabIfNeeded();
      OnboardingService.instance.onStoryCompleteWithoutVocabReview(added);
      return; // never show both modals
    }

    // @armando todo: only show old cta if on the story detail
    // https://jiveworld.slite.com/app/docs/xpRGfHFbYpSRvD#fd95a9d6
    // const showCtaDialog =
    //   this.story.isTheOnboardingStory && !userManager.fullAccess;
    // if (showCtaDialog) {
    //   const onboardingService = OnboardingService.instance;
    //   if (onboardingService.isDismissed('completedStoryCta')) {
    //     return; // don't show CTA if reviewed and recompleted while still trial
    //   }
    //   onboardingService.dismiss('completedStoryCta');
    //   // delay was apparently needed by the story overflow menu mark complete flow
    //   // hopefully is harmless for the other flows
    //   setTimeout(() => {
    //     window.presentEndOfStoryCtaDialog();
    //   }, 500);
    // }
  }

  // note, this leaves 'lastListened' along will will still be considered 'notEmpty' even after reset
  resetStory() {
    track('story__reset', { storySlug: this.slug });
    this.setStoryState(StoryState.UNQUEUED);
    this.currentPoint = LocationPointer.create({});
    this.furthestPoint = LocationPointer.create({});
    // we'll add UI control if vocab or soundbites should be reset or not in the future
    // this.clearVocabs();
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
    this.story?.ensureCacheState()?.catch(bugsnagNotify); // /async
  }

  unlockChapter(chapterRef: ChapterRef): void {
    this.markCompleteChapter(this.story?.priorChapterRef(chapterRef));
  }

  reviewChapter(chapterRef: ChapterRef): void {
    track('story__review_chapter', {
      storySlug: this.slug,
      unit: chapterRef?.unit,
      chapter: chapterRef?.chapter,
    });
    this.currentPoint = LocationPointer.create({
      unit: chapterRef.unit,
      chapter: chapterRef.chapter,
      iteration: 3,
    });
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
  }

  restartAtChapter(chapterRef: ChapterRef): void {
    track('story__restart_at_chapter', {
      storySlug: this.slug,
      unit: chapterRef?.unit,
      chapter: chapterRef?.chapter,
    });
    this.currentPoint = LocationPointer.create({ ...chapterRef, iteration: 1 });
    this.furthestPoint = this.currentPoint;
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
  }

  resumeStudy(): void {
    track('story__resume_stody', { storySlug: this.slug });
    this.currentPoint = this.furthestPoint; // should we clone it?
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
  }

  markCurrentChapterComplete(): void {
    this.markCompleteChapter(this.currentPoint);
  }

  // should probably only ever be used with current chapter
  // also used by "skip to chapter"
  markCompleteChapter(chapterRef: ChapterRef): void {
    track('story__mark_chapter_complete', {
      storySlug: this.slug,
      //...chapterRef, // beware, this shortcut resulted in a cyclic error under some condition
      unit: chapterRef?.unit,
      chapter: chapterRef?.chapter,
    });
    achieve('action:chapter-completed');
    const { userSettings } = this.root.userManager.userData;
    userSettings.dismissTip('onboardingComplete'); // redundant to keep consistent with wider logic
    const { story } = this;
    if (!story) {
      bugsnagNotify(
        `markCompleteChapter - story not resolved for slug: ${this.slug}`
      );
      return;
    }
    const nextChapterRef = story.nextChapterRef(chapterRef);
    if (!nextChapterRef) {
      this.markStoryComplete();
      return;
    }

    window.setTimeout(() => {
      OnboardingService.instance.onChapterComplete({
        chapter: this.story.chapterForPoint(nextChapterRef),
      });
    }, 800);

    const iteration = this.furthestPoint.beforeChapter(nextChapterRef) ? 1 : 3;
    const locationData = { ...nextChapterRef, iteration }; // default values are fine for the rest of the properties

    if (this.furthestPoint.matchesChapter(nextChapterRef)) {
      log.info(`advancing to furthest chapter - resuming from furthest point`);
      this.currentPoint = this.furthestPoint;
    } else {
      runInAction(() => {
        this.currentPoint = LocationPointer.create(locationData);
        if (this.furthestPoint.isLessThan(this.currentPoint)) {
          this.furthestPoint = LocationPointer.create(locationData);
        }
      });
    }
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async
  }

  get unplayed(): boolean {
    return !this.played;
  }

  get played(): boolean {
    return this.furthestPoint?.played;
  }

  // unplayed
  get unstarted(): boolean {
    return !this.started;
  }

  // spans both 'in progress' and 'completed' status
  get started(): boolean {
    return (
      this.storyState === StoryState.STARTED ||
      // not sure how, but storyState was left undefined in at least one case after migration
      (!this.storyState && this.deducedStoryState === StoryState.STARTED)
    );
  }

  get queued(): boolean {
    return this.storyState === StoryState.QUEUED;
  }

  get unqueued(): boolean {
    return this.storyState === StoryState.UNQUEUED || !this.storyState;
  }

  get completed(): boolean {
    return (
      this.furthestPoint?.chapter === END_OF_STORY_CHAPTER && !this.deleted
    );
  }

  get deleted(): boolean {
    return this.storyState === StoryState.DELETED;
  }

  get currentlyAtEnd(): boolean {
    return this.currentPoint?.chapter === END_OF_STORY_CHAPTER;
  }

  get unitCount(): number {
    return this.story?.unitCount ?? 0; // don't barf if missing story
  }

  get inProgress(): boolean {
    // return this.started && !this.completed;
    /// tentatively change to include "In review" stories also
    return this.started && !this.currentlyAtEnd;
  }

  get completedChapters(): number {
    // const chaptersCompleted = progress.storyProgress?.furthestPoint ? progress.storyProgress.furthestPoint.chapter + progress.storyProgress.furthestPoint.iteration - 2 : null;
    // if (this.furthestPoint?.chapter && this.furthestPoint?.iteration) {
    //   return this.furthestPoint.chapter + this.furthestPoint.iteration - 2;
    // } else {
    //   return 'n/a';
    // }
    // return this.furthestPoint?.completedChapters;
    return this.story?.countChaptersBefore(this.furthestPoint) || 0; // don't barf if classroom points to missing story
  }

  get displayProgress(): string {
    // todo: localize
    // todo: figure out why this.story is sometimes undefined
    return `${this.completedChapters}/${this.story?.chapterCount} chapters complete`;
  }

  setStoryState(state: StoryState) {
    log.debug(`setStoryState: ${state}`);
    this.storyState = state;
  }

  // // todo: reconsider if this state should be denormalized or not
  // // migrate bogota progress data structure
  // migrateStoryState(): boolean {
  //   if (this.storyState) {
  //     // don't touch if we already have an explicit status value
  //     return;
  //   }

  //   const deduced = this.deducedStoryState;
  //   if (this.storyState !== deduced) {
  //     log.info(`migrateStoryState: ${this.slug} -> ${deduced}`);
  //     this.setStoryState(deduced);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  get deducedStoryState(): StoryState {
    if (this.furthestPoint.played) {
      return StoryState.STARTED;
    } else {
      return StoryState.UNQUEUED;
    }
  }

  resolvePointerUnitNumbers() {
    const { storyManager } = this.root;
    // this.transformVocabListToMap(); // if we do this first, then we need to remove the old slugs after mapped to new slugs

    const unit = storyManager.unitForSlug(this.slug);
    if (unit) {
      this.currentPoint.unit = unit.unitNumber;
      this.furthestPoint.unit = unit.unitNumber;

      // if (this.needsVocabMigration) {
      //   try {
      //     const newSlugs = await unit.fetchDataAndMigrateVocabSlugs(
      //       this.vocabs // use old schema as source data
      //     );
      //     this.addVocabs(newSlugs);
      //   } catch (networkError) {
      //     // can assume we only receive error here if already identified as a network error
      //     this.migrationPendingVocabs = this.vocabs;
      //   }
      // }
    } else {
      log.error(
        `transformUnitProgress - failed to match unit for slug: ${this.slug}`
      );
    }
    // handled within merge logic
    // this.pendingBogotaVocabs[this.slug] = this.vocabs;

    // this.migrateStoryState(); // handled when merged into main record
  }

  // // strip away and archive unmatched vocab slugs
  // archiveOrphanedVocabSlugs() {
  //   const currentSlugs = this.vocabList;
  //   if (hasBogotaVocabSlugs(currentSlugs)) {
  //     const orphanedSlugs = this.vocabList.filter(slug =>
  //       isBogotaVocabSlug(slug)
  //     );
  //     for (const orphan of orphanedSlugs) {
  //       this.removeVocab(orphan);
  //     }
  //     log.error(`orphaned slugs: ${orphanedSlugs.join(',')}`);
  //     this.orphanedVocabs = orphanedSlugs;
  //   }
  // }

  // async migratePendingBogotaVocabsIfNeeded({
  //   persist,
  // }: {
  //   persist: boolean;
  // }): Promise<boolean> {
  //   if (this.hasPendingBogotaVocabs) {
  //     const result = await this.migratePendingBogotaVocabs({ persist });
  //     return result;
  //   } else {
  //     return false;
  //   }
  // }

  get hasPendingBogotaVocabs(): boolean {
    return notEmpty(this.pendingBogotaVocabs);
  }

  get pendingBogotaVocabCount(): number {
    if (isEmpty(this.pendingBogotaVocabs)) return 0;
    return Array.from(this.pendingBogotaVocabs.values()).reduce(
      (sum, list) => sum + list.length,
      0
    );
  }

  get hasDirtyVocabSlugs(): boolean {
    try {
      for (const slug of this.vocabList) {
        if (isDirtyMasalaElementId(slug)) {
          return true;
        }
      }
    } catch (error) {
      // paranoia
      bugsnagNotify(error as Error);
    }
    return false;
  }

  // true when initial migration was half processed due to missing catalog data
  get hasBorkedMigrationData(): boolean {
    return !this.deleted && !!this.vocabs && (this.vocabs?.length || 0) > 0;
  }

  // async sanitizeDirtyVocabSlugs({ persist }: { persist: boolean }) {
  //   try {
  //     const dirtySlugs = this.vocabList.filter(slug =>
  //       isDirtyMasalaElementId(slug)
  //     );
  //     log.info(`sanitizeDirtyVocabSlugs: ${dirtySlugs}`);
  //     track('story__sanitize_dirty_vocabs', {
  //       storySlug: this.slug,
  //       dirtySlugs,
  //     });
  //     for (const dirtySlug of dirtySlugs) {
  //       this.removeVocab(dirtySlug, { persist: false });
  //       this.addVocab(sanitizeDirtyMasalaElementId(dirtySlug), {
  //         persist: false,
  //       });
  //     }
  //     if (persist) {
  //       await AppFactory.root.userManager.persistUserData();
  //     }
  //   } catch (error) {
  //     bugsnagNotify(`sanitizeDirtyVocabSlugs(${this.slug}) failed: ${error}`);
  //     bugsnagNotify(error as Error);
  //   }
  // }

  get hasOrphanedVocabs(): boolean {
    return notEmpty(this.orphanedVocabs);
  }

  // // will reattempt to migrate vocabs slugs left in the 'orphaned' state (if needed)
  // // returns true of new data matched
  // async migratePendingBogotaVocabs({
  //   persist,
  // }: {
  //   persist: boolean;
  // }): Promise<boolean> {
  //   if (!this.hasPendingBogotaVocabs) {
  //     if (this.hasDirtyVocabSlugs) {
  //       // needed to handle the case that dirty vocabs were already migrated
  //       await this.sanitizeDirtyVocabSlugs({ persist });
  //       return true;
  //     }
  //     return false;
  //   }

  //   log.warn(`migratePendingBogotaVocabs(${this.slug})`);
  //   try {
  //     AppFactory.root?.setDebugStatus('migrating vocab slugs');
  //     const { story } = this;
  //     if (!story) {
  //       throw Error(
  //         `migratePendingBogotaVocabs - null story for progress record: ${this.slug}`
  //       );
  //     }
  //     // await story.ensureVolumeDetailData();

  //     for (const [
  //       unitSlug,
  //       bogotaVocabs,
  //     ] of this.pendingBogotaVocabs.entries()) {
  //       // const unit = story.unitDataBySlug(unitSlug);
  //       // if (unit) {
  //       //   // const newSlugs = await unit.fetchDataAndMigrateVocabSlugs(
  //       //   //   bogotaVocabs
  //       //   // );
  //       //   const newSlugs = unit.migrateBogotaVocabSlugs(bogotaVocabs);
  //       //   this.addVocabs(newSlugs);
  //       // } else {
  //       const migrationMap = await story.fetchUnitVocabMigrationMap(unitSlug);
  //       // if (migrationMap) {
  //       // const newSlugs = await unit.fetchDataAndMigrateVocabSlugs(
  //       //   bogotaVocabs
  //       // );
  //       const newSlugs = migrateBogotaVocabSlugs(bogotaVocabs, migrationMap);
  //       this.addVocabs(newSlugs, { persist: false });
  //       // }
  //       // bugsnagNotify(
  //       //   Error(
  //       //     `migratePendingBogotaVocabs - unresolved unit data: ${unitSlug}`
  //       //   )
  //       // );
  //     }

  //     const orphanedSlugs = this.vocabList.filter(slug =>
  //       isBogotaVocabSlug(slug)
  //     );
  //     log.warn(`orphanedSlugs: ${JSON.stringify(orphanedSlugs)}`);

  //     this.orphanedVocabs.push(...orphanedSlugs);
  //     this.removeVocabs(orphanedSlugs, { persist: false });
  //     // this.pendingBogotaVocabs.clear();
  //     this.pendingBogotaVocabs = undefined;
  //     if (persist) {
  //       await AppFactory.root.userManager.persistUserData();
  //     }
  //     return true;
  //   } catch (error) {
  //     bugsnagNotify(
  //       `migratePendingBogotaVocabs(${this.slug}) failed: ${error}`
  //     );
  //     bugsnagNotify(error as Error);
  //   } finally {
  //     AppFactory.root?.clearDebugStatus();
  //   }
  //   return false;
  // }

  // /**
  //  *
  //  * @param data legacy unit level progress data
  //  */
  // mergeProgressData(data: StoryProgress) {
  //   log.info(`mergeProgressData: ${JSON.stringify(data.snapshot)}`);
  //   if (notEmpty(data.vocabs)) {
  //     // pending bogota vocabs are segregated by unit to allow better fuzzy matching
  //     this.pendingBogotaVocabs.set(data.slug, data.vocabs);
  //   }

  //   // this.addVocabs(data.vocabList, { persist: false }); // no longer relevant to initial migration, but harmless
  //   const { unitCount, slug } = this.story;
  //   data.currentPoint.normalizePointer({
  //     unitCount,
  //     slug,
  //   });
  //   data.furthestPoint.normalizePointer({
  //     unitCount,
  //     slug,
  //   });
  //   if (this.currentPoint.isLessThan(data.currentPoint)) {
  //     // @jason, what's the cleanest way to copy this data over?
  //     applySnapshot(this.currentPoint, data.currentPoint.snapshot);
  //   }
  //   if (this.furthestPoint.isLessThan(data.furthestPoint)) {
  //     applySnapshot(this.furthestPoint, data.furthestPoint.snapshot);
  //   }
  //   if (data.lastListened ?? -1 > this.lastListened ?? 0) {
  //     this.lastListened = data.lastListened;
  //   }
  //   this.migrateStoryState();
  // }

  // // cleanup a bogota progress record which was not properly migration
  // // due to missing initial catalog data and where the unit and story slugs were the same
  // repairBorkedProgressData() {
  //   log.info(`repairBorkedProgressData[${this.slug}]`);
  //   if (notEmpty(this.vocabs)) {
  //     // pending bogota vocabs are segregated by unit to allow better fuzzy matching
  //     this.pendingBogotaVocabs.set(this.slug, this.vocabs);
  //   }
  //   this.vocabs = undefined;

  //   const { unitCount, slug } = this.story;
  //   this.currentPoint.normalizePointer({
  //     unitCount,
  //     slug,
  //   });
  //   this.furthestPoint.normalizePointer({
  //     unitCount,
  //     slug,
  //   });
  //   this.migrateStoryState();
  // }

  // transformVocabListToMap() {
  //   if (this.vocabs) {
  //     runInAction(() => {
  //       applySnapshot(this.vocabMap, {});
  //       for (const slug of this.vocabs) {
  //         this.vocabMap.set(slug, true);
  //       }
  //       this.vocabs = undefined;
  //     });
  //   } else {
  //     log.error(`transformVocabListToMap - 'vocabs' prop unexpectedly missing`);
  //   }
  // }

  beginStudying() {
    track('story__begin_studying', { storySlug: this?.slug });
    this.setStoryState(StoryState.STARTED);
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async - rethink this
    const { story } = this;
    if (story) {
      story.ensureCacheState().catch(bugsnagNotify); // async
    } else {
      bugsnagNotify(
        `beginStudying - story not resolved for slug: ${this.slug}`
      );
    }
  }

  toggleStudyLater() {
    if (this.unqueued) {
      this.markStudyLater();
    } else {
      this.unmarkStudyLater();
    }
  }

  markStudyLater() {
    track('story__study_later', { slugSlug: this.slug });
    this.setStoryState(StoryState.QUEUED);
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async - rethink this
    this.story?.ensureCacheState()?.catch(bugsnagNotify); // async
  }

  unmarkStudyLater() {
    track('story__unqueue', { slugSlug: this.slug });
    this.setStoryState(StoryState.UNQUEUED);
    this.root.userManager.persistUserData().catch(bugsnagNotify); // async - rethink this
    this.story?.ensureCacheState()?.catch(bugsnagNotify); // async
  }

  get vocabCount(): number {
    return this.vocabList.length; // + this.pendingBogotaVocabCount;
  }

  get hasPendingVocab(): boolean {
    return this.vocabCount > 0;
  }

  get learnedVocabCount(): number {
    return this.learnedVocabList.length;
  }

  get hasPendingOrLearnedVocab(): boolean {
    return this.hasPendingVocab || this.learnedVocabCount > 0;
  }

  get borkedVocabCount(): number {
    return this.vocabs?.length;
  }

  /**
   * data needed by vocab view
   */
  get vocabViewData() {
    const story = this.story;
    if (!story) return null;

    const { vocabLookupData } = story;
    const list = compact(
      this.vocabList.map(slug => {
        return (vocabLookupData as any)[slug]; // TODO
      })
    ).sort((vocabA, vocabB) => {
      if (vocabA.chapterPosition !== vocabB.chapterPosition) {
        return vocabA.chapterPosition - vocabB.chapterPosition;
      }
      return vocabA.address.localeCompare(vocabB.address);
    });
    const chapterMap: { [index: number]: any } = {}; // TODO
    list.forEach(row => {
      let chapterData = chapterMap[row.chapterPosition];
      if (!chapterData) {
        chapterData = pick(row, ['chapterPosition', 'chapterTitle']);
        chapterData.data = [];
        chapterMap[row.chapterPosition] = chapterData;
      }
      chapterData.data.push(row);
    });
    return Object.values(chapterMap);
  }

  get listeningStats(): ListeningStats {
    const { userManager } = this.root;
    if (!userManager) return null;
    return userManager.userData.storyListeningStats(this.slug);
  }

  get listeningLogs(): ListeningLog[] {
    const { userManager } = this.root;
    if (!userManager) return null;
    return userManager.userData.storyListeningLogs(this.slug);
  }

  // when an onboarding story is completed, automatically select some vocab if not enough selected
  // by the user
  autoPopulateVocabIfNeeded(): boolean {
    if (
      this.story?.isTheOnboardingStory &&
      this.vocabCount < AUTO_POPULATE_VOCAB_THRESHOLD
    ) {
      const slugs = autoPopulateVocabSlugs[this.slug];
      if (slugs) {
        this.addVocabs(slugs, { persist: true });
        return true;
      }
    }
    return false;
  }
}

export const AUTO_POPULATE_VOCAB_THRESHOLD = 5; // 8;

const autoPopulateVocabSlugs: { [index: string]: string[] } = {
  miedo: [
    'NOTATION:ovdcheaDep8U',
    'NOTATION:slP2BBvsD3Aj',
    'NOTATION:EcKhf0FIroKO',
    'NOTATION:3MqDmnwEQM0G',
    'NOTATION:Byhsq9iFD8Nw',
    'NOTATION:8gkuxSBxOGhC',
    'NOTATION:MOrsx52ZpNdZ',
    'NOTATION:VdTWm1Lh7W30',
    'NOTATION:USRBro0si2OK',
    'NOTATION:CoEGwRlUvIdm',
    'NOTATION:jDbxit6qVotp',
  ],
  'rlab-pt-stochasticity-1': [
    'NOTATION:J3E9lJZRJrJz',
    'NOTATION:JJauZIxxyu44',
    'NOTATION:i92If8jkXRYs',
    'NOTATION:RufQeFh7TCcg',
    'NOTATION:sl0l2xDY0dwg',
    'NOTATION:EUhWuuNXT9CZ',
    'NOTATION:N0QzMJ36smoO',
    'NOTATION:LukaFS8zNEqF',
    'NOTATION:32SKsQNIyWdv',
    'NOTATION:EEWAodpknYnt',
    'NOTATION:mMb3JHGJXiBg',
    'NOTATION:Ka7faj41yny9',
    'NOTATION:4XyKdghwtBoi',
  ],
};

export const vocabUsageText = (notation: ClientNotation): string => {
  const text = !!notation.usageText
    ? `${notation.usageText} (${notation.headword})`
    : notation.headword;
  return text;
};
